export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MARKETPLACE_CREDENTIALS_LIST_SUCCESS":
    case "GET_MARKETPLACE_CREDENTIALS_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceCredentialsResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_GENERATE_AD_TOKEN_REFRESH_SUCCESS":
    case "USER_CREDENTIAL_GENERATE_AD_TOKEN_REFRESH_ERROR":
      return {
        ...state,
        UserCredentialGenerateAdTokenRefreshResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_SAVE_AD_PROFILE_SUCCESS":
    case "USER_CREDENTIAL_SAVE_AD_PROFILE_ERROR":
      return {
        ...state,
        UserCredentialSaveAdProfileResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetmarketplaceListResponse: action.updatePayload,
      };
    case "DELETE_MARKETPLACE_CREDENTIALS_SUCCESS":
    case "DELETE_MARKETPLACE_CREDENTIALS_ERROR":
      return {
        ...state,
        DeleteMarketplacesCredentialsResponse: action.updatePayload,
      };
    case "GET_SELLER_TYPE_LIST_SUCCESS":
    case "GET_SELLER_TYPE_LIST_ERROR":
      return {
        ...state,
        GetSellerAccountTypeListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_MARKETPLACE_CREDENTIALS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
