export const userCentralLogEventStatus = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
  {
    label: "FATAL",
    value: "FATAL",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "UNAUTHORIZED",
    value: "UNAUTHORIZED",
  },
  {
    label: "NOT_FOUND",
    value: "NOT_FOUND",
  },
  {
    label: "NO_DATA",
    value: "NO_DATA",
  },
];
export const OperatorList = [
  {
    label: "<",
    value: "<",
  },
  {
    label: ">",
    value: ">",
  },
  {
    label: "is",
    value: "is",
  },
  {
    label: "Contains Any",
    value: "contains_Any",
  },
];
export const RuleFelids = [
  {
    label: "Budget",
    value: "budget",
  },
  {
    label: "ROAS",
    value: "ROAS",
  },
  {
    label: "Sales/Budget",
    value: "sales/budget",
  },
  {
    label: "Budget-Spent",
    value: "budget_spent",
  },
  {
    label: "Campaign Status",
    value: "campaign_status",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Hour of the day",
    value: "hour_of_the_day",
  },
  {
    label: "Weekday",
    value: "weekday",
  },
  {
    label: "Spend/Budget",
    value: "spend/budget",
  },
];

export const weekdays = [
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
  {
    label: "Sun",
    value: "Sun",
  },
];
