import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Wrapper from "./style";
import { Redirect } from "react-router-dom";
import Sidebar from "../modules/layout/sidebar";
import Footer from "../modules/layout/footer";
import { adminMenus, handleStorageChange, testArr } from "../config";
import PageRouter from "../routes";
import Header_ from "./layout/header";
import { GlobalContext } from "../commonContext";

export default function (props) {
  const [hide, setHide] = useState(false);
  const [moveToLogin, setMoveToLogin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [hideMenus, setHideMenus] = useState(false);
  const contextVar = useContext(GlobalContext);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  window.updateProfile = (e) => {
    setUserData(e);
  };

  const expandSidebar = () => {
    if (991 >= window.innerWidth) {
      const element = document.getElementById("kt_aside");
      const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10 drawer drawer-start";
      if (!element) return;
      element.classList = hide
        ? `${commonFix} drawer drawer-start drawer-on`
        : commonFix;
    }

    if (hide) {
      const node = document.createElement("div");

      node.onclick = () => setHide(false);
      node.style.zIndex = 109;
      document.getElementById("root").appendChild(node);
    } else {
      const node = document.getElementById("drawer-overlay");
      if (node !== null) {
        node.remove();
      }
    }
  };

  const checkWidth = () => {
    setHideMenus(690 > window.innerWidth);
    const element = document.getElementById("kt_aside");
    const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10";
    if (!element) return;
    element.classList =
      991 >= window.innerWidth ? `${commonFix} drawer drawer-start` : commonFix;
    element.style.width = 991 >= window.innerWidth ? "250px" : "300px";
    const node = document.getElementById("drawer-overlay");
    if (node !== null) {
      node.remove();
    }
  };

  useEffect(() => {
    setHideMenus(690 > window.innerWidth);
    window.addEventListener("resize", (e) => {
      checkWidth();
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    expandSidebar();
  }, [hide]);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 992) {
        setCollapsed(true);
        setHide(true);
      } else {
        setCollapsed(false);
        setHide(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    window.addEventListener("storage", (e) => {
      handleStorageChange(e);
    });
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const backToAdmin = () => {
    let adminDataLocal = localStorage.getItem("adminData");
    if (adminDataLocal) {
      adminDataLocal = JSON.parse(adminDataLocal);
      localStorage.setItem("user", adminDataLocal.data);
      contextVar?.updateCommonGlobalVal(
        "profile",
        JSON.parse(adminDataLocal.data)
      );
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );
      localStorage.setItem(
        "menusList",
        JSON.stringify(
          JSON.parse(adminDataLocal?.data)?.user_type == 1
            ? adminMenus
            : testArr
        )
      );
      localStorage.setItem("token", adminDataLocal.token);
      setUserData(JSON.parse(adminDataLocal.data));
      localStorage.removeItem("adminData");
    }
  };

  const GetModules = () =>
    localStorage.getItem("userType") == 2 ? false : true;

  if (!localStorage.getItem("token") || moveToLogin === true) {
    localStorage.clear();
    return <Redirect to="/login" />;
  }
  return (
    <Wrapper className="fade-in d-flex flex-column flex-root">
      <div
        className="page d-flex flex-row flex-column-fluid"
        style={{ height: "100%" }}
      >
        <div
          className="fadeInLeft"
          style={{
            zIndex: window.innerWidth >= 992 ? "999" : "1000",
            borderRight: "1px dashed #dfe1e7",
          }}
        >
          <Sidebar
            logout={() => setMoveToLogin(true)}
            hideMenus={hideMenus}
            user={userData}
            collapsed={collapsed}
            userType={GetModules()}
            setHideMenus={setHideMenus}
            setCollapsed={() => setCollapsed(!collapsed)}
            {...props}
          />
        </div>
        <div className=" d-flex flex-column flex-row-fluid">
          <Header_
            backToAdmin={() => backToAdmin()}
            backToUser={setUserData}
            hideMenus={hideMenus}
            userData={userData}
            setHideMenus={setHideMenus}
            setCollapsed={() => setCollapsed(!collapsed)}
            collapsed={collapsed}
            {...props}
          />
          <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div
              className=" flex-column flex-column-fluid"
              style={{ position: "relative" }}
            >
              <PageRouter authStatus={GetModules()} {...props} />
            </div>
            <Footer {...props} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
