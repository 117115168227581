import axiosCall from "../../configurations/network-services/axiosCall";

export const getActionLogsListAction = (data) => {
  const path = `campaign-rules/get-log-list?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&campaign_rule_id=${data?.campaign_rule_id || ""}&campaign_name=${
    data?.campaign_name || ""
  }`;
  const responseType = "GET_ACTION_LOGS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const getCampaignRulesListAction = () => {
  const path = `campaign-rules/list`;
  const responseType = "GET_CAMPAIGN_RULES_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionActionLogs = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_USER_ACTION_LOGS", state: data });
};
