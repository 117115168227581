import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionLogs from "../../modules/pages/action-logs";
import {
  getActionLogsListAction,
  getCampaignRulesListAction,
  fakeActionActionLogs,
} from "../../redux/modules/action-logs/index.action";

const mapStateToProps = (state) => ({
  GetActionLogsListResponse: state.ActionLogs.GetActionLogsListResponse,
  GetCampaignRulesListResponse: state.ActionLogs.GetCampaignRulesListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getActionLogsListAction,
      fakeActionActionLogs,

      getCampaignRulesListAction,
    },
    dispatch
  );

const ActionLogs_ = connect(mapStateToProps, mapDispatchToProps)(ActionLogs);

export default ActionLogs_;
