import axiosCall from "../../configurations/network-services/axiosCall";

export const GetMarketplacesCredentialsAction = () => {
  const path = `user-credentials`;
  const responseType = "GET_MARKETPLACE_CREDENTIALS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GenerateRefreshAdTokenAction = (data) => {
  const path = `user-credential/generate-refresh-ad-token`;
  const responseType = "USER_CREDENTIAL_GENERATE_AD_TOKEN_REFRESH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UserCredentialSaveAdProfileAction = (data) => {
  const path = `user-credential/save-ad-profile`;
  const responseType = "USER_CREDENTIAL_SAVE_AD_PROFILE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetMarketplaceListAction = () => {
  const path = `marketplaces`;
  const responseType = "GET_MARKETPLACE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteMarketplacesCredentialsAction = (id) => {
  console.log(id, "id in action");
  // const path = `user-credentials/${id}`;
  // const responseType = "DELETE_MARKETPLACE_CREDENTIALS";
  // return axiosCall("delete", path, responseType, null, {
  //   Authorization: `Bearer ${localStorage.getItem("token")}`,
  // });
};

export const GetSellerTypeListAction = () => {
  const path = `seller-types`;
  const responseType = "GET_SELLER_TYPE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionMarketplaceCredentials = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MARKETPLACE_CREDENTIALS", state: data });
};
