import { Popover, message } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";
import Pagination from "../../../../components/pagination";
import ASINTable from "../../../../components/table";
import ASINTooltip from "../../../../components/tooltip";
import { DefaultPerPage, timeZone } from "../../../../config";
import Wrapper from "./style";

export default function (props) {
  const {
    SystemEventLogsListResponse,
    fakeActionAppLog,
    SystemEventLogsListAction,
  } = props;
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  // const SystemEventLogsListResponse = useSelector(
  //   (state) => state.AppLogs.SystemEventLogsListResponse || {}
  // );

  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    SystemEventLogsListAction();
    return () => {};
  }, []);

  useEffect(() => {
    if (SystemEventLogsListResponse?.status === true) {
      setLoading(false);
      setTotalPage(SystemEventLogsListResponse?.data?.pagination?.totalCount);
      setData(SystemEventLogsListResponse?.data?.records);
      fakeActionAppLog("SystemEventLogsListResponse");
    } else if (SystemEventLogsListResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(SystemEventLogsListResponse?.message);
      fakeActionAppLog("SystemEventLogsListResponse");
    }
  }, [SystemEventLogsListResponse]);

  const columns = [
    {
      title: "Sr. No.",
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log Created",
      align: "left",
      render: (d) => {
        return (
          <span>
            {moment(new Date(d.created_at * 1000))
              .tz(timeZone)
              .format("MM/DD/YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      width: 200,
      render: (d) => {
        return (
          <ASINTooltip rule row={2}>
            {d.event_name || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (d) => {
        return <ASINTooltip rule>{d.event_type || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Event Message",
      align: "left",
      ellipsis: false,
      render: (d) => {
        return (
          <ASINTooltip rule title={d.error_message}>
            {d.error_message}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Event Data",
      align: "left",
      width: 200,
      render: (d) => {
        if (!d?.error_data) {
          return "-";
        }

        return (
          <Popover
            placement="right"
            title=""
            getPopupContainer={() =>
              document.getElementById("System_Event_Log_Table_User").parentNode
            }
            content={
              <div
                style={{
                  width: "400px",
                  wordBreak: "break-word",
                  minHeight: "20px",
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              >
                {d?.error_data}
              </div>
            }
            trigger="hover"
          >
            <div className="actionIcon">{d.error_data}</div>
          </Popover>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    SystemEventLogsListAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    SystemEventLogsListAction({
      page: 1,
      perPage: e,
    });
  };

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6 smallScreenPageHeader">System Event Log</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body py-4" id="System_Event_Log_Table_User">
                {loading ? (
                  <Loading />
                ) : (
                  <ASINTable
                    columns={columns}
                    fixed="top"
                    dataSource={data}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                  />
                )}
                <Pagination
                  loading={loading || data?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
