import axiosCall from "../../configurations/network-services/axiosCall";

export const CampaignRulesListAction = (data) => {
  const path = `campaign-rules?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&profile_id=${data?.profile_id || ""}&search_key=${
    data?.search_key || ""
  }&status=${data?.status == 1 ? "0" : data?.status == 2 ? "1" : ""}`;
  const responseType = "CAMPAIGN_RULES_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddCampaignRulesAction = (data) => {
  const path = `campaign-rules`;
  const responseType = "ADD_CAMPAIGN_RULES";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CampaignRulesLogsListAction = (data) => {
  const path = `campaign-rules/log?campaign_rule_id=${
    data?.campaign_rule_id
  }&page=${data?.page || ""}&per-page=${data?.perPage || ""}`;
  const responseType = "CAMPAIGN_RULES_LOGS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const EditCampaignRulesAction = (id, data) => {
  const path = `campaign-rules/${id}`;
  const responseType = "EDIT_CAMPAIGN_RULES";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StatusCampaignRulesAction = (id, data) => {
  const path = `campaign-rules/update-status/${id}`;
  const responseType = "UPDATE_STATUS_CAMPAIGN_RULES";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const DeleteCampaignRulesAction = (id) => {
  const path = `campaign-rules/${id}`;
  const responseType = "DELETE_CAMPAIGN_RULES";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CompaignRulesGetCompaingsAction = (id) => {
  const path = `campaign-rules/get-campaings/${id}`;
  const responseType = "GET_CAMPAIGN_RULES_GET_COMPAINGS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CompaingsDataLogListAction = (data) => {
  const path = `campaign-data/log?rule_id=${data?.rule_id || ""}&page=${
    data?.page || ""
  }&per-page=${data?.perPage || ""}`;
  const responseType = "COMPAINGS_DATA_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const MoveRuleInOtherFolderAction = (data) => {
  const path = `campaign-rule-group-bulk-move`;
  const responseType = "MOVE_RULE_IN_OTHER_FOLDER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetGroupListAction = (data) => {
  const path = `campaign-rule-group/rule-group-list?profile_id=${
    data?.profile_id || ""
  }&search_key=${data?.search_key || ""}&status=${
    data?.status == 1 ? "0" : data?.status == 2 ? "1" : ""
  }`;
  const responseType = "GET_GROUP_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplaceList = (data) => {
  const path = `marketplaces/get-marketplace-by-credential`;
  const responseType = "GET_USER_MARKETPLACE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddGroupListAction = (data) => {
  const path = `campaign-rule-group/add`;
  const responseType = "ADD_GROUP_LIST";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const EditGroupListAction = (data) => {
  const path = `campaign-rule-group/${data?.id}`;
  const responseType = "EDIT_GROUP_LIST";
  return axiosCall(
    "put",
    path,
    responseType,
    { group_name: data?.group_name, profile_id: data?.profile_id },
    {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  );
};

export const DeleteGroupListAction = (data) => {
  const path = `campaign-rule-group/${data?.id}`;
  const responseType = "DELETE_GROUP_LIST";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetUsersList = (data) => {
  const path = `user/another-user-list`;
  const responseType = "GET_USER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketPlaceListByUserAction = (data) => {
  const path = `campaign-rule-group/marketplace-wise-list/${
    data?.user_id || ""
  }`;
  const responseType = "GET_MARKETPLACE_BY_USER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CopyRuleToAnotherUserAction = (data) => {
  const path = `campaign-rule-copy`;
  const responseType = "COPY_RULE_TO_ANOTHER_USER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CopyRuleGroupToAnotherUserAction = (data) => {
  const path = `campaign-rule-group-copy`;
  const responseType = "COPY_RULEGROUP_TO_ANOTHER_USER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionRules = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_APP_RULES", state: data });
};
