import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MarketplaceCredentials from "../../modules/pages/setting/marketplace-credentials";
import {
  GetMarketplacesCredentialsAction,
  GenerateRefreshAdTokenAction,
  UserCredentialSaveAdProfileAction,
  fakeActionMarketplaceCredentials,
  GetMarketplaceListAction,
  DeleteMarketplacesCredentialsAction,
  GetSellerTypeListAction,
} from "../../redux/modules/marketplace-credentials/index.action";

const mapStateToProps = (state) => ({
  GetMarketplaceCredentialsResponse:
    state?.MarketplaceCredentials?.GetMarketplaceCredentialsResponse,
  UserCredentialGenerateAdTokenRefreshResponse:
    state?.MarketplaceCredentials?.UserCredentialGenerateAdTokenRefreshResponse,
  UserCredentialSaveAdProfileResponse:
    state?.MarketplaceCredentials?.UserCredentialSaveAdProfileResponse,
  GetmarketplaceListResponse:
    state?.MarketplaceCredentials?.GetmarketplaceListResponse,
  DeleteMarketplacesCredentialsResponse:
    state?.MarketplaceCredentials?.DeleteMarketplacesCredentialsResponse,
  GetSellerAccountTypeListResponse:
    state?.state?.MarketplaceCredentials?.GetSellerAccountTypeListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetMarketplacesCredentialsAction,
      GenerateRefreshAdTokenAction,
      GetMarketplaceListAction,
      UserCredentialSaveAdProfileAction,
      DeleteMarketplacesCredentialsAction,
      GetSellerTypeListAction,
      fakeActionMarketplaceCredentials,
    },
    dispatch
  );

const MarketplaceCredentials_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceCredentials);

export default MarketplaceCredentials_;
