import Auth from "../../modules/auth/index.reducer";
import User from "../../modules/admin/user/index.reducer";
import AppLogs from "../../modules/application-logs/index.reducer";
import Rules from "../../modules/rules/index.reducer";
import AdvertisingSP from "../../modules/advertising-reports/sponsored-product-campaigns/index.reducer";
import AdvertisingSB from "../../modules/advertising-reports/sponsored-brand-campaigns/index.reducer";
import AdvertisingSBV from "../../modules/advertising-reports/sponsored-brand-video-campaigns/index.reducer";
import AdvertisingSD from "../../modules/advertising-reports/sponsored-display-campaigns/index.reducer";
import MarketplaceCredentials from "../../modules/marketplace-credentials/index.reducer";
import ActionLogs from "../../modules/action-logs/index.reducer";
import DefaultRules from "../../modules/admin/default-rules/index.reducer";
const reducers = {
  Auth,
  User,
  AppLogs,
  Rules,
  AdvertisingSP,
  AdvertisingSB,
  AdvertisingSBV,
  AdvertisingSD,
  MarketplaceCredentials,
  ActionLogs,
  DefaultRules,
};

export default reducers;
