import Wrapper from "./style";

import React, { useEffect, useState } from "react";
import Loading from "../../../../components/loading";
import CustomTooltip from "../../../../components/tooltip";
import { Button, Popconfirm, Select, Tooltip, message } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

import Icons from "../../../../components/icons";
import { SearchOutlined } from "@ant-design/icons";
import ASINTooltip from "../../../../components/tooltip";
import {
  DefaultPerPage,
  clientID,
  returnURL,
  urlDecode,
} from "../../../../config";
import { useSelector } from "react-redux";
import ASINTable from "../../../../components/table";
import AddNewAddModalView from "./lib/new-add";
import { useHistory, useLocation } from "react-router-dom";
import AdList from "./lib/adList";

export default function (props) {
  const {
    GetMarketplacesCredentialsAction,
    GenerateRefreshAdTokenAction,
    DeleteMarketplacesCredentialsAction,
    fakeActionMarketplaceCredentials,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [addNewStatus, setAddNewStatus] = useState(true);
  const userData = JSON.parse(localStorage.getItem("user"));

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);

  const [adListModal, setAdListModal] = useState(false);
  const [adList, setAdList] = useState([]);

  const [addNewAddModal, setAddNewAddModal] = useState(false);

  const GetMarketplaceCredentialsResponse = useSelector(
    (state) =>
      state.MarketplaceCredentials?.GetMarketplaceCredentialsResponse || {}
  );
  const UserCredentialGenerateAdTokenRefreshResponse = useSelector(
    (state) =>
      state.MarketplaceCredentials
        ?.UserCredentialGenerateAdTokenRefreshResponse || {}
  );

  const DeleteMarketplacesCredentialsResponse = useSelector(
    (state) =>
      state.MarketplaceCredentials?.DeleteMarketplacesCredentialsResponse || {}
  );

  const [selectedObj, setSelectedObj] = useState({
    seller_name: JSON.parse(localStorage.getItem("user"))?.seller_name,
    marketplace_id: "ATVPDKIKX0DER",
  });

  useEffect(() => {
    if (
      UserCredentialGenerateAdTokenRefreshResponse?.status === true ||
      UserCredentialGenerateAdTokenRefreshResponse?.status === false
    ) {
      message.destroy();
      localStorage.removeItem("adCode");
      if (UserCredentialGenerateAdTokenRefreshResponse?.status) {
        setAdList(
          JSON.parse(UserCredentialGenerateAdTokenRefreshResponse?.data)
        );
        setAdListModal(true);
        message.success(UserCredentialGenerateAdTokenRefreshResponse?.message);
      } else {
        message.error(UserCredentialGenerateAdTokenRefreshResponse?.message);
      }
      fakeActionMarketplaceCredentials(
        "UserCredentialGenerateAdTokenRefreshResponse"
      );
    }
  }, [UserCredentialGenerateAdTokenRefreshResponse]);

  useEffect(() => {
    if (GetMarketplaceCredentialsResponse?.status === true) {
      setListLoading(false);
      setList(
        GetMarketplaceCredentialsResponse?.data?.map((d) => {
          return {
            ...d,
            credential_details_: JSON.parse(d?.credential_details),
          };
        })
      );
      if (!adListModal) {
        fakeActionMarketplaceCredentials("GetMarketplaceCredentialsResponse");
      }
    } else if (GetMarketplaceCredentialsResponse?.status === false) {
      setList([]);
      setListLoading(false);
      fakeActionMarketplaceCredentials("GetMarketplaceCredentialsResponse");
    }
  }, [GetMarketplaceCredentialsResponse]);

  useEffect(() => {
    localStorage.removeItem("adCode");
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(clientID);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    document.getElementById("amazon-root").appendChild(a);

    setListLoading(true);
    GetMarketplacesCredentialsAction();

    return () => {};
  }, []);

  useEffect(() => {
    if (location.pathname.includes("callback_ads")) {
      if (location.search) {
        const { code, marketplace_id, seller_name } = urlDecode(location);
        if (code && code !== undefined && code !== "undefined") {
          localStorage.setItem("adCode", code);
          history.replace("/callback_ads");
          message.destroy();
          message.loading("Loading...");
          GenerateRefreshAdTokenAction({
            ad_code: localStorage.getItem("adCode"),
            marketplace_id,
            seller_name,
          });
        } else {
          message.destroy();
          message.warning("something went wrong");
        }
      } else {
        setListLoading(true);
        GetMarketplacesCredentialsAction();
      }
    }
  }, [location]);

  useEffect(() => {
    if (DeleteMarketplacesCredentialsResponse.status) {
      message.destroy();
      message.success(DeleteMarketplacesCredentialsResponse?.message);
      GetMarketplacesCredentialsAction();
      fakeActionMarketplaceCredentials("DeleteMarketplacesCredentialsResponse");
    } else if (DeleteMarketplacesCredentialsResponse.status === false) {
      message.destroy();
      message.error(DeleteMarketplacesCredentialsResponse?.message);
      fakeActionMarketplaceCredentials("DeleteMarketplacesCredentialsResponse");
    }
  }, [DeleteMarketplacesCredentialsResponse]);

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return <span>{1 + i}</span>;
      },
    },
    {
      title: "Seller Account Name",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.credential_details_?.seller_name || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Profile ID",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.credential_details_?.profile_id || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Credential Type",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.credential_type || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Client ID",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.credential_details_?.client_id || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Country Code",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.credential_details_?.country_code || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Currency Code",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.credential_details_?.currency_code || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Time Zone",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.credential_details_?.time_zone || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        return (
          <div className="d-flex justify-content-center" id="userActionIcons">
            <Popconfirm
              title="Are you sure to delete this Credentials?"
              placement="left"
              cancelText="No"
              getPopupContainer={() =>
                document.getElementById("credential-table").parentNode
              }
              okText="Yes"
              onConfirm={() => {
                if (text?.id) {
                  console.log(text?.id, "id");
                  // message.loading("Deleting...", 0);
                  DeleteMarketplacesCredentialsAction(text?.id);
                }
              }}
            >
              <div className="Delete-Icon-Antd d-flex">
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById("credential-table").parentNode
                  }
                  title="Delete"
                  placement="left"
                >
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-5 p-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-bottom-dashed">
              <h3 className="card-title fw-bold text-dark">
                Amazon Advertising Credentials List
              </h3>
              <div className="card-toolbar">
                <Button
                  onClick={() => {
                    setAddNewAddModal(true);
                  }}
                  type="primary"
                >
                  Add
                </Button>
                {/* <a
                  id="LoginWithAmazon"
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    let options = {};
                    options.scope = "advertising::test:create_account";
                    // options.scope = "advertising::campaign_management";
                    // options.scope = "cpc_advertising:campaign_management";
                    options.response_type = "code";
                    window.amazon.Login.authorize(
                      options,
                      `${returnURL}?marketplace_id=${
                        selectedObj?.marketplace_id || ""
                      }&seller_name=${selectedObj?.seller_name || ""}`
                    );
                  }}
                >
                  <img
                    border="0"
                    alt="Login with Amazon"
                    src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                    width="156"
                    height="32"
                  />
                </a> */}
              </div>
            </div>
            <div className="card-body py-2" id="credential-table">
              {listLoading ? (
                <Loading />
              ) : (
                <ASINTable
                  columns={columns}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={listLoading}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="amazon-root" />
      {addNewAddModal && (
        <AddNewAddModalView
          show={addNewAddModal}
          close={() => setAddNewAddModal(false)}
          {...props}
        />
      )}
      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          getList={() => {
            setListLoading(true);
            GetMarketplacesCredentialsAction();
          }}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
          {...props}
        />
      )}
    </>
  );

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div id="kt_content_container" className="container-fluid">
          <div className="row">
            <div className="col-md-12 cus-tab">
              <ul className="nav nav-pills mb-5 fs-6" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link fs-7 fw-bold py-3 px-7 me-2 ${
                      tab == 1 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => setTab(1)}
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    Amazon SP API Credentials
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link fs-7 fw-bold py-3 px-7 me-2 ${
                      tab == 2 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => setTab(2)}
                    aria-selected="true"
                    role="tab"
                  >
                    Amazon Advertising Credentials
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/*begin::Row*/}
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${tab === 1 ? "active show" : ""}`}
              id="kt_tab_pane_7"
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title fw-bold text-dark">
                        Amazon SP API Credentials List
                      </h3>
                      <div className="card-toolbar">
                        <div className="card-toolbar">
                          <div className="d-flex flex-stack flex-wrap gap-4">
                            <div className="position-relative">
                              <Select
                                placeholder="Select Marketplace"
                                size="large"
                                className="w-200px"
                              />
                            </div>
                            <div className="position-relative">
                              <button className="btn btn-success btn-icon py-1">
                                <SearchOutlined style={{ fontSize: "22px" }} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-2">
                      {listLoading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-175px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-100px">Region</th>
                                <th className="min-w-150px">Credential Type</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-150px">AWS Secret Key</th>
                                <th className="min-w-175px">LWA ClientID</th>
                                <th className="min-w-175px">LWA Secret</th>
                                <th className="min-w-225px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list
                                ?.filter(
                                  (d) =>
                                    d?.credential_type !== "Advertising-API"
                                )
                                ?.map((d, i) => {
                                  const credential = JSON.parse(
                                    d?.credential_details || {}
                                  );
                                  return (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>{credential?.seller_name}</td>
                                      <td>{credential?.role_arn}</td>
                                      <td>{credential?.region}</td>
                                      <td>{d?.credential_type}</td>
                                      <td>{d?.marketplace}</td>
                                      <td>{credential?.access_key}</td>
                                      <td>
                                        <CustomTooltip rule row={2}>
                                          {credential?.secret_key}
                                        </CustomTooltip>
                                      </td>
                                      <td>
                                        <CustomTooltip rule row={2}>
                                          {credential?.client_id}
                                        </CustomTooltip>
                                      </td>
                                      <td>
                                        <CustomTooltip rule row={2}>
                                          {credential?.client_secret}
                                        </CustomTooltip>
                                      </td>
                                      <td>
                                        <CustomTooltip rule row={3}>
                                          {credential?.refresh_token}
                                        </CustomTooltip>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-75px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-75px">Region</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-75px">AWS Secret Key</th>
                                <th className="min-w-75px">LWA ClientID</th>
                                <th className="min-w-75px">LWA Secret</th>
                                <th className="min-w-75px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div className="py-7 text-center">
                                    <div className="ant-empty-image ">
                                      <svg
                                        width={64}
                                        height={41}
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          transform="translate(0 1)"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <ellipse
                                            fill="#f5f5f5"
                                            cx={32}
                                            cy={33}
                                            rx={32}
                                            ry={7}
                                          />
                                          <g
                                            fillRule="nonzero"
                                            stroke="#d9d9d9"
                                          >
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                            <path
                                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                              fill="#fafafa"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className>No data found...</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tab === 2 ? "active show" : ""}`}
              id="kt_tab_pane_8"
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title fw-bold text-dark">
                        Amazon Advertising Credentials List
                      </h3>
                      <div className="card-toolbar">
                        <a
                          href
                          className="btn btn-warning fs-7 fw-bolder text-dark"
                        >
                          <i className="fa-brands fa-amazon text-dark fs-2" />{" "}
                          Login with Amazon
                        </a>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body py-2">
                      {/*begin::Table container*/}

                      {/*begin::Table*/}
                      {listLoading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th>#</th>
                                <th className="min-w-175px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-100px">Profile ID</th>
                                <th className="min-w-150px">Credential Type</th>
                                <th className="min-w-100px">Client ID</th>
                                <th className="min-w-125px">Country Code</th>
                                <th className="min-w-125px">Currency Code</th>
                                <th className="min-w-100px">Time Zone</th>
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              {list
                                ?.filter(
                                  (d) =>
                                    d?.credential_type === "Advertising-API"
                                )
                                ?.map((d, i) => {
                                  const credential_details = JSON.parse(
                                    d?.credential_details || {}
                                  );
                                  return (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>
                                        {credential_details?.seller_name || "-"}
                                      </td>
                                      <td>
                                        {credential_details?.profile_id || "-"}
                                      </td>
                                      <td>{d?.credential_type || "-"}</td>
                                      <td>
                                        {credential_details?.client_id || "-"}
                                      </td>
                                      <td>
                                        {credential_details?.country_code ||
                                          "-"}
                                      </td>
                                      <td>
                                        {credential_details?.currency_code ||
                                          "-"}
                                      </td>
                                      <td>
                                        {credential_details?.time_zone || "-"}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-75px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-75px">Region</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-75px">AWS Secret Key</th>
                                <th className="min-w-75px">LWA ClientID</th>
                                <th className="min-w-75px">LWA Secret</th>
                                <th className="min-w-75px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div className="py-7 text-center">
                                    <div className="ant-empty-image ">
                                      <svg
                                        width={64}
                                        height={41}
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          transform="translate(0 1)"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <ellipse
                                            fill="#f5f5f5"
                                            cx={32}
                                            cy={33}
                                            rx={32}
                                            ry={7}
                                          />
                                          <g
                                            fillRule="nonzero"
                                            stroke="#d9d9d9"
                                          >
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                            <path
                                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                              fill="#fafafa"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className>No data found...</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {/*end::Table*/}

                      {/*end::Table container*/}
                      {/* <div class="d-flex flex-stack flex-wrap pt-0 mt-5">
                                              <div class="fs-7 fw-bold text-gray-700 d-flex align-items-center">
                                                  <select name="" aria-controls=""
                                                      class="form-select form-select-sm form-select-solid w-75px me-3">
                                                      <option value="10">10</option>
                                                      <option value="25">25</option>
                                                      <option value="50">50</option>
                                                      <option value="100">100</option>
                                                  </select>Showing 0 to 0 of 0 entries</div>
                                              <ul class="pagination">
                                                  <li class="page-item previous">
                                                      <a href="#" class="page-link">
                                                          <i class="previous"></i>
                                                      </a>
                                                  </li>
                                                  <li class="page-item active">
                                                      <a href="#" class="page-link">1</a>
                                                  </li>
                                                  <li class="page-item">
                                                      <a href="#" class="page-link">2</a>
                                                  </li>
                                                  <li class="page-item">
                                                      <a href="#" class="page-link">3</a>
                                                  </li>
                                                  <li class="page-item">
                                                      <a href="#" class="page-link">4</a>
                                                  </li>
                                                  <li class="page-item">
                                                      <a href="#" class="page-link">5</a>
                                                  </li>
                                                  <li class="page-item">
                                                      <a href="#" class="page-link">6</a>
                                                  </li>
                                                  <li class="page-item next">
                                                      <a href="#" class="page-link">
                                                          <i class="next"></i>
                                                      </a>
                                                  </li>
                                              </ul>
                                          </div> */}
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
    </Wrapper>
  );
}
