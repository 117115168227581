export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "CAMPAIGN_RULES_LIST_SUCCESS":
    case "CAMPAIGN_RULES_LIST_ERROR":
      return {
        ...state,
        CampaignRulesListResponse: action.updatePayload,
      };
    case "ADD_CAMPAIGN_RULES_SUCCESS":
    case "ADD_CAMPAIGN_RULES_ERROR":
      return {
        ...state,
        AddCampaignRulesResponse: action.updatePayload,
      };
    case "CAMPAIGN_RULES_LOGS_LIST_SUCCESS":
    case "CAMPAIGN_RULES_LOGS_LIST_ERROR":
      return {
        ...state,
        CampaignRulesLogsListResponse: action.updatePayload,
      };
    case "EDIT_CAMPAIGN_RULES_SUCCESS":
    case "EDIT_CAMPAIGN_RULES_ERROR":
      return {
        ...state,
        EditCampaignRulesResponse: action.updatePayload,
      };
    case "UPDATE_STATUS_CAMPAIGN_RULES_SUCCESS":
    case "UPDATE_STATUS_CAMPAIGN_RULES_ERROR":
      return {
        ...state,
        UpdateStatusCampaignRulesResponse: action.updatePayload,
      };
    case "DELETE_CAMPAIGN_RULES_SUCCESS":
    case "DELETE_CAMPAIGN_RULES_ERROR":
      return {
        ...state,
        DeleteCampaignRulesResponse: action.updatePayload,
      };
    case "GET_CAMPAIGN_RULES_GET_COMPAINGS_SUCCESS":
    case "GET_CAMPAIGN_RULES_GET_COMPAINGS_ERROR":
      return {
        ...state,
        CompaignRulesGetCompaingsResponse: action.updatePayload,
      };
    case "COMPAINGS_DATA_LOG_LIST_SUCCESS":
    case "COMPAINGS_DATA_LOG_LIST_ERROR":
      return {
        ...state,
        CompaingsDataLogListResponse: action.updatePayload,
      };
    case "GET_GROUP_LIST_SUCCESS":
    case "GET_GROUP_LIST_ERROR":
      return {
        ...state,
        GetGroupListResponse: action.updatePayload,
      };
    case "ADD_GROUP_LIST_SUCCESS":
    case "ADD_GROUP_LIST_ERROR":
      return {
        ...state,
        AddGroupListResponse: action.updatePayload,
      };
    case "EDIT_GROUP_LIST_SUCCESS":
    case "EDIT_GROUP_LIST_ERROR":
      return {
        ...state,
        EditGroupListResponse: action.updatePayload,
      };
    case "DELETE_GROUP_LIST_SUCCESS":
    case "DELETE_GROUP_LIST_ERROR":
      return {
        ...state,
        DeleteGroupListResponse: action.updatePayload,
      };
    case "GET_USER_MARKETPLACE_LIST_SUCCESS":
    case "GET_USER_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetUserMarketplaceList: action.updatePayload,
      };
    case "MOVE_RULE_IN_OTHER_FOLDER_SUCCESS":
    case "MOVE_RULE_IN_OTHER_FOLDER_ERROR":
      return {
        ...state,
        MoveRuleInOtherFolder: action.updatePayload,
      };
    case "GET_USER_LIST_SUCCESS":
    case "GET_USER__LIST_ERROR":
      return {
        ...state,
        GetUserList: action.updatePayload,
      };
    case "GET_MARKETPLACE_BY_USER_LIST_SUCCESS":
    case "GET_MARKETPLACE_BY_USER_LIST_ERROR":
      return {
        ...state,
        GetMarketPlaceListByUser: action.updatePayload,
      };
    case "COPY_RULE_TO_ANOTHER_USER_SUCCESS":
    case "COPY_RULE_TO_ANOTHER_USER_ERROR":
      return {
        ...state,
        CopyRuleToAnotherUser: action.updatePayload,
      };
    case "COPY_RULEGROUP_TO_ANOTHER_USER_SUCCESS":
    case "COPY_RULEGROUP_TO_ANOTHER_USER_ERROR":
      return {
        ...state,
        CopyRuleGroupToAnotherUser: action.updatePayload,
      };
    case "FAKE_ACTION_APP_RULES":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
