import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Profile from "../../modules/pages/setting/profile";
import { updateUserAction, ChangePasswordAction, fakeActionUser } from '../../redux/modules/admin/user/index.action';

const mapStateToProps = (state) => ({
    UpdateUserResponse: state.User.UpdateUserResponse,
    ChangePasswordResponse: state.User.ChangePasswordResponse
});

const mapDispatchToProps = (dispatch) => bindActionCreators({updateUserAction, ChangePasswordAction, fakeActionUser}, dispatch);

const Profile_ = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default Profile_;
