import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CentralLog from "../../modules/pages/application-logs/central-log";
import {
  GetSystemEventProcessAction,
  GetMarketplacesAction,
  EventProcessTypeAction,
  EventNameListAction,
  fakeActionAppLog,
} from "../../redux/modules/application-logs/index.action";

const mapStateToProps = (state) => ({
  GetSystemEventProcessResponse: state.AppLogs.GetSystemEventProcessResponse,
  GetMarketplaceListResponse: state.AppLogs.GetMarketplaceListResponse,
  EventProcessTypeListResponse: state.AppLogs.EventProcessTypeListResponse,
  EventNameListResponse: state.AppLogs.EventNameListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSystemEventProcessAction,
      GetMarketplacesAction,
      EventProcessTypeAction,
      EventNameListAction,
      fakeActionAppLog,
    },
    dispatch
  );

const CentralLog_ = connect(mapStateToProps, mapDispatchToProps)(CentralLog);

export default CentralLog_;
