import styled from "styled-components";

const Wrapper = styled.div`
  
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
`;

export default Wrapper;
