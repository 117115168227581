import React, { useEffect, useRef, useState } from "react";
import Wrapper from "./style";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { adminMenus, testArr } from "../../../config";
import SignUp from "./lib/sign-up";
import ForgotPassword from "./lib/forgot-password";

const mockSignUpResponse = {
  status: true,
  data: {
    auth_token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6ImZoXldpcCFhRzZETHNtaFMifQ.eyJpc3MiOiJ0cmFrdG9yIiwiYXVkIjoidHJha3RvciIsImp0aSI6ImZoXldpcCFhRzZETHNtaFMiLCJpYXQiOjE3MTg3MDI3NzQsImV4cCI6MTcxODc4OTE3NCwidWlkIjozMn0.4_y5g63OdB0kVvE1KWMxbr_MedndQuV5HLhZfQIAdkc",
    user_data: {
      id: 32,
      rds_credential_id: 1,
      server_credential_id: 1,
      parent: null,
      name: "signup test 2",
      seller_name: "signup test 2",
      user_type: 1,
      email: "signup2@traktor.com",
      photo: null,
      db_name: null,
      contact_no: null,
      address: null,
      company_registration_number: null,
      company_name: null,
      vat: null,
      status: null,
      pending_reports: null,
      avg_order_per_day: null,
      avg_sku: null,
      total_sales: null,
      total_spend: null,
      is_big_data: null,
      user_status: null,
      amazon_advertising_client_id: null,
      amazon_advertising_client_secret: null,
      amazon_advertising_return_url: null,
      amazon_advertising_response: null,
      amazon_advertising_access_token: null,
      amazon_advertising_refresh_token: null,
      amazon_advertising_access_token_expiry: null,
      amazon_advertising_token_type: null,
      created_at: 1718702769,
      updated_at: 1718702769,
      deleted_at: null,
      deleted_by: null,
    },
    // Add other data properties as needed
  },
  message: "Mocked sign up successful message", // Change message for testing different scenarios
};

export default function (props) {
  const focusFirst = useRef();
  const {
    loginAction,
    signUpAction,
    forgotPasswordAction,
    resetPasswordAction,
    fakeActionAuth,
  } = props;
  const [viewStatus, setViewStatus] = useState("login");
  const [form] = Form.useForm();
  const [token, setToken] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hasSearchParams = Array.from(searchParams.keys()).length > 0;

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const LoginResponse = useSelector((state) => state.Auth.LoginResponse || {});

  useEffect(() => {
    if (hasSearchParams) {
      setViewStatus("forgot-password");
    }
  }, [hasSearchParams]);

  useEffect(() => {
    if (LoginResponse?.status === true) {
      message.destroy();
      const userType = LoginResponse?.data?.user_data?.user_type;
      const is_user_credential =
        LoginResponse?.data?.user_data?.is_user_credential;

      localStorage.setItem(
        "user",
        JSON.stringify(LoginResponse?.data?.user_data)
      );
      localStorage.setItem("token", LoginResponse?.data?.auth_token);
      localStorage.setItem("userType", userType);
      localStorage.setItem(
        "menusList",
        JSON.stringify(userType == 1 ? adminMenus : testArr)
      );

      setLoading(false);

      setRedirect(
        userType === 1
          ? "/manage-users"
          : is_user_credential === 0 || null
          ? "/setting/marketplace-credentials"
          : "/rules"
      );

      setTimeout(() => {
        message.success(
          userType === 1
            ? LoginResponse?.message
            : is_user_credential === 0 || null
            ? "You need to add Seller account credentials to start using the application"
            : LoginResponse?.message,
          5
        );
      }, 500); //500 milliseconds delay for Displaying message

      fakeActionAuth("LoginResponse");
    } else if (LoginResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(LoginResponse?.message || "Something Went Wrong");
      fakeActionAuth("LoginResponse");
    }
  }, [LoginResponse]);

  const isLogin = (value) => {
    localStorage.clear();
    loginAction(value);
    setLoading(true);
  };

  const eventLogin = (e) => {
    if (e.key === "Enter" && !loading) {
      const userId1 = document.getElementById("username");
      const password2 = document.getElementById("password");
      if (userId1 !== null && password2 !== null) {
        if (userId1.value !== "" && password2.value !== "") {
          const eventLoading = {
            username: userId1.value,
            password: password2.value,
          };
          isLogin(eventLoading);
        }
      }
    }
  };

  // if (viewStatus === "sign-up") {
  //   return SignUp(
  //     form,
  //     signUpAction,
  //     fakeActionAuth,
  //     loading,
  //     setViewStatus,
  //     setLoading,
  //     isLogin
  //   );
  // }

  // if (viewStatus === "forgot-password") {
  //   return ForgotPassword(
  //     form,
  //     fakeActionAuth,
  //     loading,
  //     setViewStatus,
  //     setLoading
  //   );
  // }

  useEffect(() => {
    focusFirst?.current?.focus();
    window?.addEventListener("keyup", eventLogin);
    return () => window?.removeEventListener("keyup", eventLogin);
  }, []);

  if (localStorage.getItem("token") || redirect) {
    return <Redirect to={redirect} />;
  }

  if (viewStatus === "sign-up") {
    return (
      <SignUp
        form={form}
        signUpAction={signUpAction}
        fakeActionAuth={fakeActionAuth}
        loading={loading}
        setViewStatus={setViewStatus}
        setLoading={setLoading}
      />
    );
  }

  if (viewStatus === "forgot-password" || hasSearchParams) {
    return (
      <ForgotPassword
        form={form}
        forgotPasswordAction={forgotPasswordAction}
        resetPasswordAction={resetPasswordAction}
        fakeActionAuth={fakeActionAuth}
        loading={loading}
        setViewStatus={setViewStatus}
        setLoading={setLoading}
        viewStatus={viewStatus}
      />
    );
  }

  return (
    <Wrapper
      id="login_"
      className="d-flex flex-column flex-lg-row flex-column-fluid"
    >
      <div className="d-flex flex-lg-row-fluid d-none d-md-flex">
        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          <img
            className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
            src="/assets/logo-main.png"
            alt
          />
          <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
            Fast, Efficient and Productive
          </h1>
        </div>
      </div>
      <div className="d-flex flex-column-fluid justify-content-center justify-content-lg-end px-15 py-8">
        <div
          className="authFadeInBottom bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10"
          style={{ zoom: "85%", height: "fit-content", margin: "auto" }}
        >
          <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
            <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
              <form className="form w-100">
                <div className="text-center mb-10">
                  <img className="h-55px shimmer" src="/assets/logo.png" alt />
                </div>
                <div className="text-center mb-10">
                  <h1 className="text-dark fw-bolder mb-3">
                    Sign In To Traktor
                  </h1>
                  <div className="text-gray-500 fw-semibold fs-6">
                    Your Social Campaigns
                  </div>
                </div>
                <Form
                  form={form}
                  name="login"
                  scrollToFirstError
                  layout="vertical"
                >
                  <Form.Item
                    name="username"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      autoFocus
                      ref={focusFirst}
                      placeholder="E-mail"
                      id="username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      id="password"
                      size="large"
                      placeholder="Password"
                    />
                  </Form.Item>
                </Form>
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5">
                  <div />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewStatus("forgot-password")}
                    className="link-primary"
                  >
                    Forget Password ?
                  </span>
                </div>
                <div className="d-grid mb-10">
                  <Button
                    loading={loading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          isLogin(values);
                        })
                        .catch((info) => {});
                    }}
                    size="large"
                    className="antd-success"
                  >
                    Sign In
                  </Button>
                </div>
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Not a Member yet?
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewStatus("sign-up")}
                    className="link-primary"
                  >
                    Sign Up
                  </span>
                </div>
              </form>
            </div>
            <div style={{ alignSelf: "center" }} className="d-flex flex-stack">
              <div className="d-flex fw-semibold text-primary fs-base gap-5">
                <Link>Terms</Link>
                <Link>Plans</Link>
                <Link>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
