import { createGlobalStyle } from "styled-components";

const AppStyleWrapper = createGlobalStyle`
body {
    background-color: #F9FAF5;
    font-family: 'Inter';
}
#webpack-dev-server-client-overlay {
  display: none;
}
::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgb(255 255 255 / 40%);
    border: 2px solid rgb(255 255 255);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid rgb(110 175 38);
    background-clip: content-box;
    border-radius: 10px;
    background-color: rgb(110 175 38 / 66%) !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    background-color: rgb(110 175 38 / 100%) !important;
  }
  .ant-table-content,
  #login_,
  .ant-table-body,
  .gridScroll,
  .notificationList,
  #add_user,
  #add_rules,
  #kt_aside_menu_wrapper,
  .sidebar_asinwiser,
  #scroll-hide-view-modal,
  #kt_wrapper {
    &::-webkit-scrollbar {
      width: 10px !important;
    height: 10px !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent !important;
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent !important;
      background-clip: content-box;
      background-color: transparent !important;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #fbb1a5 !important;
    }
    &:hover {
    &::-webkit-scrollbar {
    width: 10px !important;
    height: 12px !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgb(255 255 255 / 40%);
    border: 2px solid rgb(255 255 255);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid rgb(110 175 38);
    background-clip: content-box;
    border-radius: 10px;
    background-color: rgb(110 175 38 / 66%) !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    background-color: rgb(110 175 38 / 100%) !important;
  }
    }
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgb(255 255 255),
      #ffffff
    ) !important;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .ant-tooltip-inner {
    background-color: rgb(255 255 255) !important;
    font-weight: 500;
    color: #000 !important;
  }
  .ant-tooltip-arrow:before {
    background: white !important;
  }
  .ant-tooltip-inner {
    color: #000 !important;
    a {
      color: #000 !important;
    }
  }

    .shimmer {
        -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
          100%;
        background-repeat: no-repeat;
        animation: shimmer 2.5s infinite;
    }
    @keyframes shimmer {
        100% {
          -webkit-mask-position: left;
        }
    }
  ::selection {
    color: #6eaf26;
    background: #6eaf2666;
  }
  body > #root {
    height: 100%;
  }
  body > #root > .globalHeight {
    height: 100%;
  }
  .antd-success {
    color: #fff !important;
    background-color: #6eaf26 !important;
  }
  .authFadeInBottom {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInBottom;
  }

  @keyframes authFadeInBottom {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }
    to {
      opacity: 1;
    }
  }



  .fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeInBottom {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
  }

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      width: 0px !important;
      ${"" /* transform: translateY(-100%); */}
    }
    to {
      opacity: 1;
    }
  }
  .fadeInLeft {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
    }
  }
  .fadeInRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(30px, 30px, 8px);
    }
    to {
      opacity: 1;
    }
  }
  .fadeInHideRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInHideRight;
  }

  @keyframes fadeInHideRight {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      height: 0px;
    }
  }

  @media only screen and (min-width: 550px) {
    .smallScreenPageHeader {
      display: none !important;
    }
  }
  .ml-auto {
    margin-left: auto;
  }
  #kt_wrapper {
    overflow: auto;
    height: 100%;
  }


  .Edit-Icon-Antd {
    cursor: pointer;
    margin: 0px 10px;
    svg {
      transition: 0.6s;
      font-size: 20px;
      path:first-child {
        fill: white;
      }
      path {
        fill: grey;
      }
    }
    &:hover {
      svg {
        transform: scale(1.2);
        path {
          fill: grey;
        }
      }
    }
  }
  .info-product-research {
    padding: 0 !important;
    font-size: 11px !important;
  }
.Eye-Icon-Antd, .Copy-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  width: 23px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path::nth-child(3), path::nth-child(4) {
      fill: #0062ff;
    }
    path::nth-child(2) {
      fill: #91caff;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path {
        fill: #0062ff;
      }
      path::nth-child(3), path::nth-child(4) {
        fill: #FFF !important;
      }
    }
  }
}
.Download-Icon-Antd {
  cursor: pointer;
  svg {
    transition: 0.6s;
    font-size: 20px;
    color: #00a1ff;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
.File-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: #7472ee;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: #7472ee56;
      }
      path {
        fill: #7472ee;
      }
    }
  }
}
.Delete-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: rgb(255 114 114);
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: rgb(255 114 114);
      }
      path {
        fill: rgb(255 114 114);
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.align-item-center {
  align-items: center;
}
.ant-table-layout-fixed table {
    table-layout: auto;
}
.ant-upload-list-item-error {
  border-color: #6eaf2661 !important;
}
.ant-btn-lg {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}


  input:-webkit-autofill {
    background-color: #fff !important; /* Change background color */
    color: #000 !important; /* Change text color */
    box-shadow: 0 0 0 30px #fff inset !important; /* Create a solid background */
    -webkit-text-fill-color: #000 !important; /* Ensure text color is applied */
  }

  .ant-input-affix-wrapper >input.ant-input:focus {
    box-shadow: 0 0 0 30px #fff inset !important; /* Create a solid background */
  }

    .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-bottom: 0;
    margin-inline-start: 8px;
    background-color: #6eaf26 !important;
  }

.ant-table-pagination-custom-rule {
      position: relative;
      .ant-pagination-total-text {
            position: absolute;
    left: 0;
    top: 0;
    right: 0;
        width: fit-content;
      }
}
`;

export default AppStyleWrapper;
