import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Rules from "../../modules/pages/rules copy";
import {
  CampaignRulesListAction,
  AddCampaignRulesAction,
  EditCampaignRulesAction,
  DeleteCampaignRulesAction,
  CompaignRulesGetCompaingsAction,
  fakeActionRules,
  StatusCampaignRulesAction,
  CompaingsDataLogListAction,
  CampaignRulesLogsListAction,
  GetGroupListAction,
  AddGroupListAction,
  EditGroupListAction,
  MoveRuleInOtherFolderAction,
  GetMarketplaceList,
  DeleteGroupListAction,
  GetUsersList,
  CopyRuleToAnotherUserAction,
  CopyRuleGroupToAnotherUserAction,
  GetMarketPlaceListByUserAction,
} from "../../redux/modules/rules/index.action";

const mapStateToProps = (state) => ({
  CampaignRulesListResponse: state.Rules.CampaignRulesListResponse,
  AddCampaignRulesResponse: state.Rules.AddCampaignRulesResponse,
  EditCampaignRulesResponse: state.Rules.EditCampaignRulesResponse,
  DeleteCampaignRulesResponse: state.Rules.DeleteCampaignRulesResponse,
  CampaignRulesLogsListResponse: state.Rules.CampaignRulesLogsListResponse,
  UpdateStatusCampaignRulesResponse:
    state.Rules.UpdateStatusCampaignRulesResponse,
  CompaignRulesGetCompaingsResponse:
    state.Rules.CompaignRulesGetCompaingsResponse,
  CompaingsDataLogListResponse: state.Rules.CompaingsDataLogListResponse,
  GetGroupListResponse: state.Rules.GetGroupListResponse,
  AddGroupListResponse: state.Rules.AddGroupListResponse,
  EditGroupListResponse: state.Rules.EditGroupListResponse,
  DeleteGroupListResponse: state.Rules.DeleteGroupListResponse,
  GetUserMarketplaceList: state.Rules.GetUserMarketplaceList,
  MoveRuleInOtherFolder: state.Rules.MoveRuleInOtherFolder,
  GetUsersList: state.Rules.GetUsersList,
  CopyRuleToAnotherUser: state.Rules.CopyRuleToAnotherUser,
  CopyRuleGroupToAnotherUser: state.Rules.CopyRuleGroupToAnotherUser,
  GetMarketPlaceListByUser: state.Rules.GetMarketPlaceListByUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CampaignRulesListAction,
      AddCampaignRulesAction,
      EditCampaignRulesAction,
      DeleteCampaignRulesAction,
      CompaignRulesGetCompaingsAction,
      StatusCampaignRulesAction,
      CampaignRulesLogsListAction,
      CompaingsDataLogListAction,
      fakeActionRules,
      GetGroupListAction,
      MoveRuleInOtherFolderAction,
      AddGroupListAction,
      EditGroupListAction,
      GetMarketplaceList,
      DeleteGroupListAction,
      GetUsersList,
      CopyRuleToAnotherUserAction,
      CopyRuleGroupToAnotherUserAction,
      GetMarketPlaceListByUserAction,
    },
    dispatch
  );

const RulesHide_ = connect(mapStateToProps, mapDispatchToProps)(Rules);

export default RulesHide_;
