import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Icons from "../components/icons";
export const timeZone = "America/Los_Angeles";
export const pageDropdown = [10, 25, 50, 100];

export const returnURL = "https://projecttractor.com/callback_ads";
export const clientID =
  "amzn1.application-oa2-client.19dea203b56f4adc8235bb25779836ad";

export const API_URL = "https://api.projecttractor.com/api/v1/";
export const EndPoint = "https://api.projecttractor.com/";
export const DefaultPerPage = 10;
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};

export const clearLocalData = () => {
  localStorage.clear();
  window.location.assign("/login");
  window.location.reload();
};

export const handleStorageChange = (e) => {
  e.stopPropagation();
  if (e.url === window.location.href) {
    if (!e.key) {
      clearLocalData();
    } else {
      localStorage.setItem(e.key, e.oldValue);
    }
  }
  if (
    !localStorage.getItem("token") ||
    !localStorage.getItem("user") ||
    !localStorage.getItem("userType") ||
    !localStorage.getItem("menusList")
  ) {
    clearLocalData();
  }
};

export const timeSince = (date) => {
  const a = moment(new Date()).tz(timeZone);
  const b = moment(new Date(date * 1000)).tz(timeZone);
  var seconds = Math.floor(a.diff(b, "second"));

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const testArr = [
  "dashboard",
  "rules",
  "action-logs",
  "advertising-reports",
  "setting",
  "application-logs",
];
export const adminMenus = ["dashboard", "manage-users"];

const GetIcons = (icon) => {
  return <Icons type={icon} />;
};

const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);

const Modules = [
  {
    title: "Manage Users",
    name: "manage-users",
    path: ["/manage-users"],
    index: [
      {
        admin: 2,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("users"),
  },
  {
    title: "Default Rules",
    name: "default-rules",
    path: ["/default-rules"],
    index: [
      {
        admin: 2,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("default-rules"),
  },
  {
    title: "Dashboard",
    name: "dashboard",
    path: ["/", "/dashboard"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["admin", "common"],
    icon: GetIcons("dashboard"),
  },
  {
    title: "Rules",
    name: "rules",
    path: ["/rules"],
    index: [
      {
        admin: -1,
        common: 2,
      },
    ],
    rule: ["common"],
    icon: GetIcons("rules"),
  },
  {
    title: "Action-Logs",
    name: "action-logs",
    path: ["/action-logs"],
    index: [
      {
        admin: -1,
        common: 3,
      },
    ],
    rule: ["common"],
    icon: GetIcons("action-logs"),
  },
  {
    title: "Advertising Reports",
    name: "advertising-reports",
    parentPath: "/advertising-reports",
    path: [],
    index: [
      {
        admin: -1,
        common: 4,
      },
    ],
    rule: ["common"],
    icon: GetIcons("advertising-reports"),
    children: [
      {
        title: "Sponsored Product Campaigns",
        name: "sponsored-product-campaigns",
        path: ["/sponsored-product-campaigns"],
        index: [],
        parent: "advertising-reports",
        icon: bullet,
      },
      {
        title: "Sponsored Brand Campaigns",
        name: "sponsored-brand-campaigns",
        path: ["/sponsored-brand-campaigns"],
        index: [],
        parent: "advertising-reports",
        icon: bullet,
      },
      {
        title: "Sponsored Brand Video Campaigns",
        name: "sponsored-brand-video-campaigns",
        path: ["/sponsored-brand-video-campaigns"],
        index: [],
        parent: "advertising-reports",
        icon: bullet,
      },
      {
        title: "Sponsored Display Campaigns",
        name: "sponsored-display-campaigns",
        path: ["/sponsored-display-campaigns"],
        index: [],
        parent: "advertising-reports",
        icon: bullet,
      },
    ],
  },
  {
    title: "Setting",
    name: "setting",
    parentPath: "/setting",
    path: [],
    index: [
      {
        admin: -1,
        common: 5,
      },
    ],
    rule: ["common"],
    icon: GetIcons("setting_"),
    children: [
      {
        title: "Profile",
        name: "profile",
        path: ["/profile"],
        index: [],
        parent: "setting",
        icon: bullet,
      },
      {
        title: "Marketplace Credentials",
        name: "marketplace-credentials",
        path: ["/marketplace-credentials"],
        index: [],
        parent: "setting",
        icon: bullet,
      },
    ],
  },
  {
    title: "Application Logs",
    name: "application-logs",
    parentPath: "/application-logs",
    path: [],
    index: [
      {
        admin: -1,
        common: 5,
      },
    ],
    rule: ["common"],
    icon: GetIcons("application-logs"),
    children: [
      {
        title: "Central Log",
        name: "central-log",
        path: ["/central-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "System Error Log",
        name: "system-error-log",
        path: ["/system-error-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
    ],
  },
];

const LinksMenu = (label, key, children) => {
  return (
    <Link
      id={Math.random()}
      to={{
        pathname: `${children ? "/" + children : ""}/${key}`,
        state: {},
      }}
    >
      {label}
    </Link>
  );
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children: Array.isArray(children) ? children : null,
    label:
      children && Array.isArray(children)
        ? label
        : LinksMenu(label, key, children),
    type,
  };
}

export const convertSidebarLinks = (list, mode) => {
  return list
    ?.sort(function (a, b) {
      return a?.index?.[0]?.[mode] - b?.index?.[0]?.[mode];
    })
    ?.map((d) => {
      if (d?.children && d?.children?.length !== 0) {
        return getItem(
          d?.title,
          d?.name,
          d?.icon,
          d?.children?.map((c) => {
            return getItem(c.title, c.name, c.icon, c.parent);
          })
        );
      }
      return getItem(d.title, d.name, d.icon);
    });
};

export const MyNewSidebarFunc = (userMenus, mode) => {
  if (mode === "admin") {
    return userMenus;
  }
  const menuList = localStorage.getItem("menusList")
    ? JSON.parse(localStorage.getItem("menusList")) || []
    : [];
  let menus = [];
  userMenus.forEach((d) => {
    const index = menuList?.findIndex((a) => a === d.key);
    if (index !== -1) {
      menus.push(d);
    }
  });
  return menus;
};

export const ModulesList = (props) => {
  const { type, mode } = props;
  if (type === "sidebar") {
    return MyNewSidebarFunc(
      convertSidebarLinks(
        Modules?.filter((d) => d?.rule?.findIndex((t) => t === mode) !== -1),
        mode
      ),
      mode
    );
  } else if (type === "url") {
    let obj = {};
    Modules.forEach((r) => {
      r.path.forEach((p) => {
        obj = {
          ...obj,
          [p]: {
            name: r.title,
            parent: r.parentName,
            path: r.parentPath,
          },
        };
      });
      r?.children?.forEach((p) => {
        p.path.forEach((i) => {
          obj = {
            ...obj,
            [i]: {
              name: p?.title,
              path: r?.parentPath + i,
              parent: r?.title,
            },
          };
        });
      });
    });
    return obj;
  }
  return [];
};
