export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "GET_ACTION_LOGS_LIST_SUCCESS":
      case "GET_ACTION_LOGS_LIST_ERROR":
        return {
          ...state,
          GetActionLogsListResponse: action.updatePayload,
        };
      case "GET_CAMPAIGN_RULES_LIST_SUCCESS":
      case "GET_CAMPAIGN_RULES_LIST_ERROR":
        return {
          ...state,
          GetCampaignRulesListResponse: action.updatePayload,
        };
      case "FAKE_ACTION_USER_ACTION_LOGS":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  