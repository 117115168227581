import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import RootLoading from "../components/loading/rootLoading";
import { MyNewSidebarFunc, clearLocalData } from "../config";
import asyncComponent from "../core/helper/asyncComponent";
import { adminRoutersList, userRoutersList } from "./lib";
import { Button, Result } from "antd";

function PageRouter(props) {
  const { authStatus } = props;
  let menus = authStatus ? adminRoutersList : MyNewSidebarFunc(userRoutersList);

  return (
    <Suspense fallback={<RootLoading />}>
      <Switch>
        {menus
          ?.filter(
            (person, index) =>
              index === menus.findIndex((other) => person.path === other.path)
          )
          ?.map((d, i) => {
            return (
              d && <Route key={i} path={d?.path} component={d?.name} exact />
            );
          })}
        <Route
          path="*"
          render={() => (
            <div>
              {" "}
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Button type="primary" onClick={() => clearLocalData()}>
                    Back Home
                  </Button>
                }
              />
            </div>
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default PageRouter;
