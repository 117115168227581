import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "../../modules/pages/auth";
import {
  loginAction,
  fakeActionAuth,
  signUpAction,
  forgotPasswordAction,
  resetPasswordAction,
} from "../../redux/modules/auth/index.action";

const mapStateToProps = (state) => ({
  LoginResponse: state.Auth.LoginResponse,
  SignUpResponse: state.Auth.SignUpResponse,
  ForgotPasswordResponse: state.Auth.ForgotPasswordResponse,
  ResetPasswordResponse: state.Auth.ResetPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginAction,
      signUpAction,
      forgotPasswordAction,
      resetPasswordAction,
      fakeActionAuth,
    },
    dispatch
  );

const Login_ = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login_;
