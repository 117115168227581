import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./style";
import ASINTable from "../../../../components/table";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import Loading from "../../../../components/loading";
import NoData from "../../../../components/no-data";
import Pagination from "../../../../components/pagination";
import {
  DefaultPerPage,
  testArr,
  timeSince,
  timeZone,
} from "../../../../config";
import { Button, Input, Popconfirm, Tooltip, message } from "antd";
import moment from "moment-timezone";
import ASINTooltip from "../../../../components/tooltip";
import Icons from "../../../../components/icons";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import UserAdd from "./lib/user-add";
import { GlobalContext } from "../../../../commonContext";

export default function (props) {
  const history = useHistory();
  const { getUserAction, switchUserAction, deleteUserAction, fakeActionUser } =
    props;
  const [loading, setLoading] = useState(true);
  const contextVar = useContext(GlobalContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [data, setData] = useState([]);

  const [userModal, setUserModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [filter, setFilter] = useState({
    user_name: "",
  });

  const UserListResponse = useSelector(
    (state) => state.User.UserListResponse || {}
  );
  const SwitchUserResponse = useSelector(
    (state) => state.User.SwitchUserResponse || {}
  );
  const DeleteUserResponse = useSelector(
    (state) => state.User.DeleteUserResponse || {}
  );

  const getList = () => {
    setLoading(true);
    setPage(1);
    setPageSize(DefaultPerPage);
    getUserAction({
      page: 1,
      perPage: DefaultPerPage,
    });
  };

  useEffect(() => {
    getList();
    return () => {};
  }, []);

  useEffect(() => {
    if (UserListResponse?.status === true) {
      setLoading(false);
      setData(UserListResponse.data.records);
      setTotalPage(UserListResponse.data.pagination.totalCount);
      fakeActionUser("UserListResponse");
    } else if (UserListResponse?.status === false) {
      setLoading(false);
      message.warning(UserListResponse?.message);
      fakeActionUser("UserListResponse");
    }
  }, [UserListResponse]);

  useEffect(() => {
    if (SwitchUserResponse.status) {
      message.destroy();
      let adminToken = localStorage.getItem("token");
      let adminData = localStorage.getItem("user");
      const data = {
        token: adminToken,
        data: adminData,
      };
      localStorage.setItem("adminData", JSON.stringify(data));

      localStorage.setItem(
        "user",
        JSON.stringify(SwitchUserResponse?.data?.user_data)
      );
      localStorage.setItem(
        "userType",
        SwitchUserResponse?.data?.user_data?.user_type
      );
      localStorage.setItem("menusList", JSON.stringify(testArr));
      localStorage.setItem("token", SwitchUserResponse?.data?.auth_token);
      contextVar?.updateCommonGlobalVal(
        "profile",
        SwitchUserResponse?.data?.user_data
      );
      history.replace("/rules");
      message.success(SwitchUserResponse.message);
      fakeActionUser("SwitchUserResponse");
    } else if (SwitchUserResponse.status === false) {
      message.destroy();
      message.error(SwitchUserResponse.message);
      fakeActionUser("SwitchUserResponse");
    }
  }, [SwitchUserResponse]);

  useEffect(() => {
    if (DeleteUserResponse.status) {
      message.destroy();
      message.success(DeleteUserResponse?.message);
      getList();
      fakeActionUser("DeleteUserResponse");
    } else if (DeleteUserResponse.status === false) {
      message.destroy();
      message.error(DeleteUserResponse?.message);
      fakeActionUser("DeleteUserResponse");
    }
  }, [DeleteUserResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    getUserAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    getUserAction({
      page: 1,
      perPage: e,
    });
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }
    return color;
  };

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Name",
      align: "left",
      render: (item) => {
        const color_ = getRandomColor();
        return (
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <Link>
                <div
                  className="symbol-label fs-3 text-danger bg-light-danger"
                  style={{ border: "1px dashed #f1416c7a" }}
                >
                  {item?.name?.[0] || "NA"}
                </div>
              </Link>
            </div>
            <div className="d-flex flex-column">
              <Link className="text-gray-800 text-hover-primary mb-1 fw-bolder">
                {item?.name || "NA"}
              </Link>
              <span>{item?.email || "NA"}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Pending Reports",
      align: "left",
      render: (text) => {
        return <span>{text?.pending_reports || "0"}</span>;
      },
    },
    {
      title: "Contact No",
      align: "left",
      render: (text) => {
        return <span>{text?.contact_no || "N/A"}</span>;
      },
    },

    {
      title: "Switch User",
      render: (text) => {
        return (
          <span
            onClick={() => {
              message.destroy();
              message.loading("Loading...");
              switchUserAction(text.id);
            }}
            className="d-flex align-items-center fw-bolder text-dark cursor-pointer"
          >
            <span className="svg-icon svg-icon-success svg-icon-2x me-2">
              <Icons type="userSwitch" />
            </span>
            Switch User
          </span>
        );
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Updated At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.updated_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.updated_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        return (
          <div className="d-flex justify-content-center" id="userActionIcons">
            <div
              className="Edit-Icon-Antd d-flex"
              onClick={() => {
                setUserModal(true);
                setSelectedRow(text);
              }}
            >
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById("user-table-admin").parentNode
                }
                title="Edit"
                placement="left"
              >
                <EditTwoTone />
              </Tooltip>
            </div>
            <Popconfirm
              title="Are you sure to delete this user?"
              placement="left"
              cancelText="No"
              getPopupContainer={() =>
                document.getElementById("user-table-admin").parentNode
              }
              okText="Yes"
              onConfirm={() => {
                if (text?.id) {
                  message.loading("Deleting...", 0);
                  deleteUserAction(text?.id);
                }
              }}
            >
              <div className="Delete-Icon-Antd d-flex">
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById("user-table-admin").parentNode
                  }
                  title="Delete"
                  placement="left"
                >
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6 smallScreenPageHeader">Manage Seller Users </h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header  d-flex justify-content-between align-items-center">
                <div>
                  <Input
                    placeholder="Search User"
                    className="w-200px mx-4"
                    size="large"
                    value={filter?.user_name || null}
                    onPressEnter={() => {
                      setPage(1);
                      setPageSize(DefaultPerPage);
                      setLoading(true);
                      setData([]);
                      console.log(filter, "filter");
                      getUserAction({
                        ...filter,
                        page: 1,
                        perPage: DefaultPerPage,
                      });
                    }}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        user_name: e.target.value,
                      });
                    }}
                  />
                </div>

                <div>
                  <Button
                    className="antd-success"
                    size="large"
                    // disabled
                    onClick={() => {
                      setUserModal(true);
                      setSelectedRow({});
                    }}
                  >
                    Create User
                  </Button>
                </div>
              </div>
              <div className="separator separator-dashed mb-0" />
              <div className="card-body py-4" id="user-table-admin">
                <div className="table-responsive">
                  {loading ? (
                    <Loading />
                  ) : (
                    <ASINTable
                      columns={columns}
                      fixed="top"
                      dataSource={data}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  )}
                </div>
                <Pagination
                  loading={loading || data?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {userModal && (
        <UserAdd
          show={userModal}
          data={selectedRow}
          close={() => {
            setUserModal(false);
            setSelectedRow({});
          }}
          getList={() => {
            getList();
          }}
          {...props}
        />
      )}
    </Wrapper>
  );
}
