import styled from "styled-components";

const Wrapper = styled.div`
  font-family: "Poppins", "sans-serif";
  background: #f9faf5;
  min-height: 70px;
  max-height: 70px;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0px 0px 20px 20px #0000000d;
  .ant-breadcrumb-separator {
    position: relative;
    top: -1px;
    font-size: 1.5rem !important;
    margin-inline: 5px !important;
    font-family: Poppins,sans-serif !important;
}
  .page-title {
    .ant-breadcrumb {
      margin-top: 5px;
    }
  }
  .container-fluid {
    padding: 0 30px !important;
  }
  .ant-breadcrumb-separator {
    position: relative;
    top: -1px;
    font-size: 1.5rem !important;
    margin-inline: 5px !important;
  }
  container-fluid-sm {
    padding: 0 0px !important;
  }
  .arrow {
    background: white;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    color: #6eaf26;
    padding-left: 6px;
    box-shadow: 0px 0px 10px #d2d2d2;
    position: absolute;
    left: -16px;
    padding-top: 6px;
    cursor: pointer;
    z-index: 999;
    svg,
    span {
      cursor: pointer;
    }
  }
  .header-sm {
    display: flex-root !important;
  }
  #btnBackToAdmin {
    background-color: rgb(110 175 38);
    transition-duration: 300ms;
  }
  #btnBackToAdmin:hover {
    background-color: rgb(86 147 20) !important;
  }
  .ant-breadcrumb,
  .anticon {
    font-size: 1.3rem;
  }
  .ant-breadcrumb > ol > li {
    display: flex;
  }
  .ant-breadcrumb a:hover {
    color: rgba(0, 0, 0, 0.88) !important;
    background-color: #fff !important;
  }
  // .ant-breadcrumb > ol > li > span > a {
  //   color: #898989bf !important;
  // }

  @media only screen and (max-width: 550px) {
    .ant-breadcrumb {
      display: none;
    }
  }
    
  @media only screen and (max-width: 550px) {
    .ant-breadcrumb {
      display: none;
    }
  }
`;

export default Wrapper;
