import { message } from "antd";
import Nprogress from "nprogress";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { urlDecode } from "../../config";
import "./progress.css";
import RootLoading from "../../components/loading/rootLoading";

export default function asyncComponent(DisplayComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        mounted: false,
      };
      Nprogress.configure({ showSpinner: false });
      Nprogress.start();
      document.title += ".";
    }

    async componentDidMount() {
      setTimeout(() => {
        this.mounted = true;
        message.destroy();
        Nprogress.done();
        if (this.mounted) {
          this.setState({
            mounted: false,
          });
        }
      }, 200);
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { mounted } = this.state;
      const { location } = this.props;
      if (mounted === true) {
        return <RootLoading />;
      }
      if (
        Object.keys(urlDecode(location))?.filter((d) => !d)?.length === 0 &&
        location?.search &&
        location?.pathname !== "/spapi-callback" &&
        location?.search &&
        location?.pathname !== "/callback_ads"
      ) {
        return (
          <Redirect
            to={{
              pathname: location?.pathname,
              search: "",
              state: urlDecode(location),
            }}
          />
        );
      }

      return (
        <div className="globalHeight">
          <DisplayComponent {...this.props} />
        </div>
      );
    }
  }
  return AsyncFunc;
}
