import { Button, Modal, Tabs, Tag } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";
import NoData from "../../../../components/no-data";
import ASINTable from "../../../../components/table";
import ASINTooltip from "../../../../components/tooltip";
import { DefaultPerPage, timeSince, timeZone } from "../../../../config";
import Pagination from "../../../../components/pagination";

export default function (props) {
  const {
    show,
    close,
    selectedRow,
    CampaignRulesLogsListAction,
    fakeActionRules,
    CompaingsDataLogListAction,
  } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [compaignsDataLoading, setCompaignsDataLoading] = useState(true);
  const [compaignsData, setCompaignsData] = useState([]);

  const [tab, setTab] = useState("0");

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [pageTab, setPageTab] = useState(1);
  const [totalPageTab, setTotalPageTab] = useState(1);
  const [pageSizeTab, setPageSizeTab] = useState(DefaultPerPage);

  const CampaignRulesLogsListResponse = useSelector(
    (state) => state.Rules.CampaignRulesLogsListResponse || {}
  );
  const CompaingsDataLogListResponse = useSelector(
    (state) => state.Rules.CompaingsDataLogListResponse || {}
  );

  console.log(data, "datadata");

  useEffect(() => {
    if (CompaingsDataLogListResponse?.status === true) {
      setTotalPageTab(
        CompaingsDataLogListResponse?.data?.pagination?.totalCount
      );
      setCompaignsData(CompaingsDataLogListResponse?.data?.records || []);
      setCompaignsDataLoading(false);
      fakeActionRules("CompaingsDataLogListResponse");
    } else if (CompaingsDataLogListResponse?.status === false) {
      setCompaignsDataLoading(false);
      fakeActionRules("CompaingsDataLogListResponse");
    }
  }, [CompaingsDataLogListResponse]);

  useEffect(() => {
    if (CampaignRulesLogsListResponse?.status === true) {
      setTotalPage(CampaignRulesLogsListResponse?.data?.pagination?.totalCount);
      setData(CampaignRulesLogsListResponse?.data?.records || []);
      setLoading(false);
      fakeActionRules("CampaignRulesLogsListResponse");
    } else if (CampaignRulesLogsListResponse?.status === false) {
      setLoading(false);
      fakeActionRules("CampaignRulesLogsListResponse");
    }
  }, [CampaignRulesLogsListResponse]);

  useEffect(() => {
    if (show && selectedRow?.id) {
      setLoading(true);
      CampaignRulesLogsListAction({
        page: page,
        perPage: pageSize,
        campaign_rule_id: selectedRow?.id,
      });
      setCompaignsDataLoading(true);
      CompaingsDataLogListAction({
        page: pageTab,
        perPage: pageSizeTab,
        rule_id: selectedRow?.id,
      });
    }
  }, [show]);

  const columnsCompaignData = [
    {
      title: "#",
      render: (_, __, i) => {
        return <span>{(pageTab - 1) * pageSizeTab + 1 + i}</span>;
      },
    },
    {
      title: "Created at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000))
                .tz(timeZone)
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Campaign Name",
      width: 180,
      render: (item) => {
        return (
          <b>
            <ASINTooltip rule row={2}>
              {item?.campaign_name || "-"}
            </ASINTooltip>
          </b>
        );
      },
    },
    {
      title: "Campaign Id",
      width: 150,
      render: (item) => {
        return <span>{item?.id || "-"}</span>;
      },
    },
    {
      title: "Campaign Data",
      width: 220,
      render: (item) => {
        return (
          <ASINTooltip rule row={2}>
            {item?.data || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Failed Criteria",
      width: 220,
      render: (item) => {
        return (
          <ASINTooltip rule row={2}>
            {item?.failed_criteria || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Updated at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .tz(timeZone)
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.updated_at)})
            </span>
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Created at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000))
                .tz(timeZone)
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.created_at)})
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Log Date",
    //   render: (item) => {
    //     return (
    //       <span>
    //         {item?.log_date
    //           ? moment(item?.log_date).tz(timeZone).format("MMM DD, YYYY")
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Campaign Name",
      width: 180,
      render: (item) => {
        return (
          <b>
            <ASINTooltip rule row={2}>
              {item?.campaign_name || "-"}
            </ASINTooltip>
          </b>
        );
      },
    },
    {
      title: "Campaign Id",
      width: 180,
      render: (item) => {
        return <span>{item?.campaign_id || "-"}</span>;
      },
    },
    {
      title: "Campaign Status",
      width: 180,
      render: (item) => {
        return <span>{item?.campaign_status || "-"}</span>;
      },
    },
    {
      title: "Campaign New Budget",
      width: 240,
      render: (item) => {
        return <span>${item?.campaign_new_budget || "-"}</span>;
      },
    },
    {
      title: "Campaign Previous Budget",
      width: 240,
      render: (item) => {
        return <span>${item?.campaign_previous_budget || "-"}</span>;
      },
    },
    {
      title: "Record type",
      width: 180,
      render: (item) => {
        return <span>{item?.record_type || "-"}</span>;
      },
    },

    {
      title: "Updated at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .tz(timeZone)
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.updated_at)})
            </span>
          </div>
        );
      },
    },
  ];

  const onPageNoTab = (e) => {
    setCompaignsDataLoading(true);
    setCompaignsData([]);
    CompaingsDataLogListAction({
      page: e,
      perPage: pageSizeTab,
      rule_id: selectedRow?.id,
    });
    setPageTab(e);
  };

  const onPerPageTab = (e) => {
    setCompaignsDataLoading(true);
    setPageTab(1);
    setPageSizeTab(e);
    setCompaignsData([]);
    CompaingsDataLogListAction({
      page: 1,
      perPage: e,
      rule_id: selectedRow?.id,
    });
  };
  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    CampaignRulesLogsListAction({
      page: e,
      perPage: pageSize,
      campaign_rule_id: selectedRow?.id,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setLoading(true);
    setPage(1);
    setPageSize(e);
    setData([]);
    CampaignRulesLogsListAction({
      page: 1,
      perPage: e,
      campaign_rule_id: selectedRow?.id,
    });
  };

  const check = (d) => d === "Criteria Passed";

  return (
    <Modal
      wrapClassName="action-log-modal"
      title={
        <div className="d-flex align-items-center">
          Action Log&nbsp;:&nbsp;
          <Tag color="success">&nbsp;{selectedRow?.rule_name}</Tag>
        </div>
      }
      open={show}
      width={850}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          Close
        </Button>,
      ]}
    >
      <Tabs
        type="card"
        activeKey={tab}
        onChange={(e) => setTab(e)}
        style={{
          marginBottom: 32,
        }}
        items={["Criteria Passed", "Criteria Not Passed"].map((d, i) => {
          return {
            label: d,
            key: i?.toString(),
            children: (check(d) ? loading : compaignsDataLoading) ? (
              <Loading />
            ) : (check(d) ? data?.length > 0 : compaignsData?.length > 0) ? (
              <>
                <ASINTable
                  columns={check(d) ? columns : columnsCompaignData}
                  dataSource={check(d) ? data : compaignsData}
                  sticky
                  rowKey="key"
                  loading={loading}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
                <Pagination
                  loading={
                    (check(d) ? loading : compaignsDataLoading) ||
                    (check(d)
                      ? data?.length === 0
                      : compaignsData?.length === 0)
                  }
                  pageSize={check(d) ? pageSize : pageSizeTab}
                  page={check(d) ? page : pageTab}
                  totalPage={check(d) ? totalPage : totalPageTab}
                  onPerPage={check(d) ? onPerPage : onPerPageTab}
                  onPageNo={check(d) ? onPageNo : onPageNoTab}
                />
              </>
            ) : (
              <NoData />
            ),
          };
        })}
      />
    </Modal>
  );
}
