import React, { useEffect, useState } from "react";
import { Button, Form, Modal, message, Input, Upload } from "antd";
import { EndPoint } from "../../../../../config";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 7,
    },
  },
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function (props) {
  const {
    show,
    close,
    data,
    addUserAction,
    getList,
    updateUserAction,
    fakeActionUser,
  } = props;

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState(
    Object.keys(data)?.length !== 0 && data?.photo
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: `${EndPoint}${data?.photo}`,
          },
        ]
      : []
  );

  const AddUserRes = useSelector((state) => state.User.AddUserResponse || {});
  const UpdateUserResponse = useSelector(
    (state) => state.User.UpdateUserResponse || {}
  );

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (UpdateUserResponse?.status === true) {
      message.destroy();
      message.success(UpdateUserResponse?.message);
      getList();
      close();
      fakeActionUser("UpdateUserResponse");
    } else if (UpdateUserResponse?.status === false) {
      message.destroy();
      message.error(UpdateUserResponse?.message);
      fakeActionUser("UpdateUserResponse");
    }
  }, [UpdateUserResponse]);

  useEffect(() => {
    if (AddUserRes?.status === true) {
      message.destroy();
      message.success(AddUserRes?.message);
      getList();
      close();
      fakeActionUser("AddUserResponse");
    } else if (AddUserRes?.status === false) {
      message.destroy();
      message.error(AddUserRes?.message);
      fakeActionUser("AddUserResponse");
    }
  }, [AddUserRes]);

  const onCreate = (values) => {
    message.destroy();
    message.loading("Loading...", 0);
    const withPic = { ...values, photo_base64: fileList?.[0]?.thumbUrl };
    const withOutPic = { ...values };

    if (fileList?.length !== 0 || fileList?.[0]?.url) {
      if (Object.keys(data)?.length === 0) {
        delete withPic.confirm;
        addUserAction({ ...withPic });
      } else {
        updateUserAction(data?.id, { ...withPic });
      }
    } else {
      if (Object.keys(data)?.length === 0) {
        delete values.confirm;
        addUserAction({ ...withOutPic });
      } else {
        updateUserAction(data?.id, { ...withOutPic });
      }
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  return (
    <Modal
      title={`${Object.keys(data)?.length === 0 ? "Add" : "Edit"} User`}
      open={show}
      width={650}
      onCancel={close}
      footer={[
        <Button size="large" key="back" onClick={close}>
          Close
        </Button>,
        <Button
          size="large"
          key="submit"
          className="antd-success"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {});
          }}
        >
          {`${Object.keys(data)?.length === 0 ? "Create" : "Update"} User`}
        </Button>,
      ]}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="add_user"
        scrollToFirstError
        layout="vertical"
        style={{
          height: "510px",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px 20px",
        }}
        initialValues={
          Object.keys(data)?.length === 0
            ? {}
            : {
                name: data.name,
                email: data.email,
                seller_name: data.seller_name,
                contact_no: data.contact_no,
                address: data.address,
              }
        }
      >
        <div className="row align-items-center">
          <Form.Item valuePropName="fileList" label="Avatar">
            <div
              className="image-input image-input-outline"
              data-kt-image-input="true"
            >
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                accept=".png, .jpg, .jpeg"
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </div>
            <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
          </Form.Item>
        </div>
        <div className="row   align-items-center">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </div>
        <div className="row   align-items-center">
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input size="large" placeholder="E-mail" />
          </Form.Item>
        </div>
        <div className="row   align-items-center">
          <Form.Item
            name="seller_name"
            label="Seller Name"
            rules={[
              {
                required: true,
                message: "Please input your Seller Name!",
              },
            ]}
          >
            <Input size="large" placeholder="Seller Name" />
          </Form.Item>
        </div>
        <div className="row   align-items-center">
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please input your address!",
              },
            ]}
          >
            <Input.TextArea size="large" placeholder="address" />
          </Form.Item>
        </div>
        <div className="row   align-items-center">
          <Form.Item
            name="contact_no"
            label="Contact No"
            rules={[
              {
                required: true,
                message: "Contact No is required",
              },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject();
                  }
                  const validation = /^[0-9]+$/.test(value);
                  if (!validation) {
                    return Promise.reject("is not valid.");
                  }
                  if (value.length < 10 || value.length > 10) {
                    return Promise.reject(
                      `can't be ${
                        value.length < 10 ? "less" : "more"
                      } than 10 digits`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              maxLength={10}
              size="large"
              autocomplete="off"
              placeholder="Contact No"
            />
          </Form.Item>
        </div>
        {Object.keys(data)?.length === 0 && (
          <>
            <div className="row   align-items-center">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  autocomplete="new-password"
                />
              </Form.Item>
            </div>
            <div className="row   align-items-center">
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  autocomplete="off"
                  size="large"
                />
              </Form.Item>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}
