import React, { useState } from "react";
import { Modal, Button, Form, Input, message } from "antd";

const GroupNameModal = ({
  open,
  onClose,
  type,
  AddGroupListAction,
  EditGroupListAction,
  marketplaceSelected,
  selectedRow,
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    if (type === "edit") {
      EditGroupListAction({
        ...selectedRow,
        ...values,
        profile_id: marketplaceSelected,
      });
    } else {
      AddGroupListAction({ ...values, profile_id: marketplaceSelected });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Regex for validation (no special characters and no spaces)
  const groupNameRegex = /[a-zA-Z0-9]+$/;

  return (
    <>
      <Modal
        title={type === "new" ? "Create New Group" : "Edit Group"}
        open={open}
        onCancel={onClose}
        footer={null}
      >
        <Form
          name="group_name_form"
          initialValues={{ group_name: selectedRow?.group_name }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="group_name"
            rules={[
              { required: true, message: "Please input your group name!" },
              {
                pattern: groupNameRegex,
                message: "No special characters or spaces allowed!",
              },
            ]}
          >
            <Input placeholder="Group Name" />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "10px",
            }}
          >
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default GroupNameModal;
