import axiosCall from "../../../configurations/network-services/axiosCall";

export const getDefaultRulesAction = (data) => {
  const path = `campaign/default-rules?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&rule_name=${data?.rule_name || ""}&status=${
    data?.status == "2" ? 1 : data?.status == "1" ? 0 : ""
  }`;
  const responseType = "GET_CAMPAIGN_DEFAULT_RULE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DefaultRulesDeleteAction = (id) => {
  const path = `campaign/default-rules/${id}`;
  const responseType = "DEFAULT_RULES_DELETE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StatusCampaignRulesAction = (id, data) => {
  const path = `campaign/default-rules/${id}`;
  const responseType = "STATUS_DEFAULT_RULE_ACTION";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddCampaignRulesDefaultAction = (data) => {
  const path = `campaign/default-rules`;
  const responseType = "ADD_CAMPAIGN_RULES_DEFAULT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const EditCampaignRulesDefaultAction = (id, data) => {
  const path = `campaign/default-rules/${id}`;
  const responseType = "EDIT_CAMPAIGN_RULES_DEFAULT";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionDefaultRules = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_DEFAULT_RULES", state: data });
};
