import React from "react";
import Wrapper from "./style";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { timeZone } from "../../../config";

export default function () {
  return (
    <Wrapper
      className="footer py-4 d-flex flex-lg-column fade-in"
      id="kt_footer"
    >
      <div className="container-fluid d-flex flex-column flex-md-row flex-stack">
        <div className="text-dark order-2 order-md-1">
          <span className="text-gray-400 fw-bold me-1">
            Copyright © {moment(new Date()).tz(timeZone).format("YYYY")}&nbsp;
            <Link
              target="_blank"
              className="text-muted text-hover-primary fw-bolder me-2 fs-6"
            >
              Adv automation
            </Link>
            | All rights reserved.
          </span>
        </div>

        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <Link target="_blank" className="menu-link px-2">
              About
            </Link>
          </li>
          <li className="menu-item">
            <Link target="_blank" className="menu-link px-2">
              Support
            </Link>
          </li>
          <li className="menu-item">
            <Link target="_blank" className="menu-link px-2">
              Purchase
            </Link>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
}
