import React, { useEffect, useState } from "react";
import { Input, Modal, Select } from "antd";
import { clientID, returnURL } from "../../../../../config";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    show,
    close,
    GetMarketplaceListAction,
    GetSellerTypeListAction,
    fakeActionMarketplaceCredentials,
  } = props;

  const [selectedObj, setSelectedObj] = useState({
    seller_name: JSON.parse(localStorage.getItem("user"))?.seller_name,
    // seller_account_type: JSON.parse(localStorage.getItem("user"))
    //   ?.seller_account_type,
    marketplace_id: null,
  });

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [sellerAccountTypeList, setSellerAccountTypeList] = useState([]);

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [sellerAccountTypeLoading, setSellerAccountTypeLoading] =
    useState(true);

  const GetmarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredentials?.GetmarketplaceListResponse || {}
  );

  const GetSellerAccountTypeListResponse = useSelector(
    (state) =>
      state.MarketplaceCredentials?.GetSellerAccountTypeListResponse || {}
  );

  useEffect(() => {
    if (GetmarketplaceListResponse?.status === true) {
      let make_ = {};
      GetmarketplaceListResponse?.data?.forEach((e) => {
        if (!make_[e.region]) {
          make_ = { ...make_, [e.region]: [] };
        }
        make_[e.region]?.push(e);
      });
      setMarketplaceList(make_);

      setMarketplaceLoading(false);
      fakeActionMarketplaceCredentials("GetmarketplaceListResponse");
    } else if (GetmarketplaceListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      fakeActionMarketplaceCredentials("GetmarketplaceListResponse");
    }
  }, [GetmarketplaceListResponse]);

  useEffect(() => {
    if (GetSellerAccountTypeListResponse?.status === true) {
      let make_ = {};
      GetSellerAccountTypeListResponse?.data?.forEach((e) => {
        if (!make_[e.region]) {
          make_ = { ...make_, [e.region]: [] };
        }
        make_[e.region]?.push(e);
      });
      setSellerAccountTypeList(make_);

      setSellerAccountTypeLoading(false);
      fakeActionMarketplaceCredentials("GetSellerAccountTypeListResponse");
    } else if (GetSellerAccountTypeListResponse?.status === false) {
      setSellerAccountTypeLoading(false);
      setSellerAccountTypeList([]);
      fakeActionMarketplaceCredentials("GetSellerAccountTypeListResponse");
    }
  }, [GetSellerAccountTypeListResponse]);

  useEffect(() => {
    GetMarketplaceListAction();
    // GetSellerTypeListAction();
    return () => {};
  }, []);

  useEffect(() => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(clientID);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";

    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);

    return () => {};
  }, []);

  return (
    <Modal
      open={show}
      centered
      onCancel={close}
      title="Add New Advertising Credentials"
      width={650}
      style={{ zIndex: 9999 }}
      footer={[
        <div className="d-flex justify-content-end">
          <button
            type="reset"
            id="kt_modal_add_customer_cancel"
            className="btn btn-light"
            onClick={() => {
              close();
            }}
          >
            Discard
          </button>

          <div id="amazon-root" />
          <a
            id="LoginWithAmazon"
            style={{
              marginLeft: "10px",
              opacity:
                Object.values(selectedObj)?.filter((d) => !d)?.length === 0
                  ? 1
                  : 0.5,
              pointerEvents:
                Object.values(selectedObj)?.filter((d) => !d)?.length === 0
                  ? "all"
                  : "none",
            }}
            onClick={() => {
              if (Object.values(selectedObj)?.filter((d) => !d)?.length !== 0) {
                return;
              }

              let options = {};
              options.scope = "advertising::campaign_management";
              options.response_type = "code";

              window.amazon.Login.authorize(
                options,
                `${returnURL}?marketplace_id=${
                  selectedObj?.marketplace_id?.id || ""
                }&seller_name=${selectedObj?.seller_name || ""}`
              );
              close();
            }}
          >
            <img
              border="0"
              alt="Login with Amazon"
              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
              width="156"
              height="32"
            />
          </a>
        </div>,
      ]}
    >
      <div className="pe-7" id="kt_modal_add_customer_scroll">
        <div className="row mb-7 align-items-center">
          <div className="col-md-4 fv-row">
            <label className=" fs-6 fw-bold mb-2">Seller Account Name</label>
          </div>
          <div className="col-md-8 fv-row">
            <Input
              placeholder="Enter Seller Account Name"
              style={{ height: "38px" }}
              className="ant_common_input"
              size="large"
              id="seller_account_name"
              value={selectedObj?.seller_name || ""}
              onChange={(e) => {
                setSelectedObj({
                  ...selectedObj,
                  seller_name: e.target.value,
                });
              }}
            />
          </div>
        </div>
        {/* <div className="row mb-7 align-items-center">
          <div className="col-md-4 fv-row">
            <label className=" fs-6 fw-bold mb-2">Seller Account Type</label>
          </div>
          <div className="col-md-8 fv-row">
            <Select
              className="me-3"
              style={{ width: "100%" }}
              size="large"
              getPopupContainer={(target) => target.parentNode}
              options={
                marketplaceList &&
                Object.keys(marketplaceList)?.length !== 0 &&
                Object.keys(marketplaceList)?.map((d, i) => {
                  return {
                    label: d,
                    options: marketplaceList[d]?.map((r) => {
                      return { label: r?.name, value: r?.id, ...r };
                    }),
                  };
                })
              }
              onChange={(_, e) => {
                setSelectedObj({
                  ...selectedObj,
                  seller_account_type: e,
                });
              }}
              value={selectedObj?.seller_account_type}
              loading={marketplaceLoading}
              placeholder="Enter Seller Account Type"
            />
          </div>
        </div> */}
        <div className="row mb-7 align-items-center">
          <div className="col-md-4 fv-row">
            <label className=" fs-6 fw-bold mb-2">Marketplace</label>
          </div>
          <div className="col-md-8 fv-row">
            <Select
              className="me-3"
              style={{ width: "100%" }}
              size="large"
              getPopupContainer={(target) => target.parentNode}
              options={
                marketplaceList &&
                Object.keys(marketplaceList)?.length !== 0 &&
                Object.keys(marketplaceList)?.map((d, i) => {
                  return {
                    label: d,
                    options: marketplaceList[d]?.map((r) => {
                      return { label: r?.name, value: r?.id, ...r };
                    }),
                  };
                })
              }
              onChange={(_, e) => {
                setSelectedObj({
                  ...selectedObj,
                  marketplace_id: e,
                });
              }}
              value={selectedObj?.marketplace_id}
              loading={marketplaceLoading}
              placeholder="Select Marketplace"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
