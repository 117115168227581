import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SponsoredProductCampaigns from "../../modules/pages/advertising-reports/sponsored-product-campaigns";
import {
  AdvertisingSPCampaignsAction,
  fakeActionAdvertisingSP,
} from "../../redux/modules/advertising-reports/sponsored-product-campaigns/index.action";

const mapStateToProps = (state) => ({
  AdvertisingSPListResponse: state.AdvertisingSP.AdvertisingSPListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AdvertisingSPCampaignsAction,
      fakeActionAdvertisingSP,
    },
    dispatch
  );

const SponsoredProductCampaigns_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsoredProductCampaigns);

export default SponsoredProductCampaigns_;
