import axiosCall from "../../../configurations/network-services/axiosCall";

export const AdvertisingSPCampaignsAction = (data) => {
  const path = `advertising/sp/campaigns?page=${data?.page || 1}&per-page=${data?.perPage || 10}`;
  const responseType = "ADVERTISING_S_P_CAMPAIGNS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAdvertisingSP = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADVERTISING_SP", state: data });
};
