import {
  CheckOutlined,
  CloseOutlined,
  DeleteTwoTone,
  EditTwoTone,
  EyeOutlined,
  FieldTimeOutlined,
  InfoCircleTwoTone,
  CaretLeftOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Popover,
  Select,
  Switch,
  Table,
  Dropdown,
  Tag,
  Tooltip,
  Space,
  message,
  Divider,
  theme,
  Modal,
} from "antd";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading";
import ASINTable from "../../../components/table";
import ASINTooltip from "../../../components/tooltip";
import { DefaultPerPage, timeSince, timeZone } from "../../../config";
import Campaigns from "./lib/Campaigns";
import ActionLog from "./lib/action-log";
import Add from "./lib/add";
import GroupNameModal from "./lib/group-action";
import Wrapper from "./style";
import ChangeGroup from "./lib/move-rule";
import NewRule from "./lib/new-rule";
const { useToken } = theme;
export default function (props) {
  const {
    CampaignRulesListAction,
    DeleteCampaignRulesAction,
    EditCampaignRulesAction,
    MoveRuleInOtherFolderAction,
    StatusCampaignRulesAction,
    fakeActionRules,
    DeleteGroupListAction,
    GetGroupListAction,
    GetMarketplaceList,
    GetUsersList,
    CopyRuleToAnotherUserAction,
    CopyRuleGroupToAnotherUserAction,
    GetMarketPlaceListByUserAction,
  } = props;
  const [loading, setLoading] = useState(true);
  const [modal, contextHolder] = Modal.useModal();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [data, setData] = useState([]);

  const [dropdownView, setDropdownView] = useState(false);

  const [groupModal, setGroupModal] = useState(false);
  const [groupModalType, setGroupModalType] = useState("new");

  const [filter, setFilter] = useState({
    search_key: "",
    status: "",
  });

  const [rulesSearch, setRulesSearch] = useState("");

  const [selectedGroup, setSelectedGroup] = useState(null);

  const [actionLogView, setActionLogView] = useState(false);

  const [marketplaceSelected, setMarketplaceSelected] = useState(null);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([]);

  const [moveRuleModal, setMoveRuleModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [campaignsModal, setCampaignsModal] = useState(false);
  const [ruleModal, setRuleModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState({});

  const [modalType, setModalType] = useState("add");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const popoverRef = useRef(null);
  const [popoverVisible, setPopoverVisible] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  const [userList, setUserList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userWiseMarketplaceList, setUserWiseMarketplaceList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [selectModal, setSelectModal] = useState("rule_group");

  const [selectedValues, setSelectedValues] = useState({
    rule: null,
    user: null,
    marketplace: null,
    group: null,
  });

  const GetUserMarketplaceList = useSelector(
    (state) => state.Rules.GetUserMarketplaceList || {}
  );

  const GetUserList = useSelector((state) => state.Rules.GetUserList || {});

  const GetMarketPlaceListByUser = useSelector(
    (state) => state.Rules.GetMarketPlaceListByUser || {}
  );

  const AddGroupListResponse = useSelector(
    (state) => state.Rules.AddGroupListResponse || {}
  );
  const DeleteCampaignRulesResponse = useSelector(
    (state) => state.Rules.DeleteCampaignRulesResponse || {}
  );
  const GetGroupListResponse = useSelector(
    (state) => state.Rules.GetGroupListResponse || {}
  );
  const UpdateStatusCampaignRulesResponse = useSelector(
    (state) => state.Rules.UpdateStatusCampaignRulesResponse || {}
  );
  const EditGroupListResponse = useSelector(
    (state) => state.Rules.EditGroupListResponse || {}
  );
  const MoveRuleInOtherFolder = useSelector(
    (state) => state.Rules.MoveRuleInOtherFolder || {}
  );
  const DeleteGroupListResponse = useSelector(
    (state) => state.Rules.DeleteGroupListResponse || {}
  );

  const CopyRuleToAnotherUser = useSelector(
    (state) => state.Rules.CopyRuleToAnotherUser || {}
  );

  const CopyRuleGroupToAnotherUser = useSelector(
    (state) => state.Rules.CopyRuleGroupToAnotherUser || {}
  );

  const getList = (marketplaceSelected_) => {
    setLoading(true);
    GetGroupListAction({ profile_id: marketplaceSelected_, ...filter });
  };

  useEffect(() => {
    if (GetUserList?.status === true) {
      if (GetUserList?.data?.length > 0) {
        setUserList(GetUserList?.data);
      }
      setLoading(false);
      fakeActionRules("GetUserList");
    } else if (GetUserList?.status === false) {
      setUserList([]);
      setLoading(false);
      fakeActionRules("GetUserList");
    }
  }, [GetUserList]);

  useEffect(() => {
    if (GetMarketPlaceListByUser?.status === true) {
      if (GetMarketPlaceListByUser?.data?.length > 0) {
        const options = GetMarketPlaceListByUser?.data?.map((d) => ({
          label: d?.marketplace,
          value: d?.marketplace_id,
        }));

        const groupOptions = GetMarketPlaceListByUser?.data?.flatMap(
          (marketplace) =>
            marketplace.group_list.map((group) => ({
              label: group.group_name,
              value: group.id,
            }))
        );

        setGroupList(groupOptions);
        setUserWiseMarketplaceList(options);
      }
      setMarketplaceLoading(false);
      fakeActionRules("GetMarketPlaceListByUser");
    } else if (GetMarketPlaceListByUser?.status === false) {
      setUserList([]);
      setMarketplaceLoading(false);
      fakeActionRules("GetMarketPlaceListByUser");
    }
  }, [GetMarketPlaceListByUser]);

  useEffect(() => {
    if (GetUserMarketplaceList?.status === true) {
      if (GetUserMarketplaceList?.data?.length > 0) {
        setMarketplaceSelected(GetUserMarketplaceList?.data?.[0]?.profile_id);
        getList(GetUserMarketplaceList?.data?.[0]?.profile_id);
      } else {
        setLoading(false);
      }
      setMarketplaceList(
        GetUserMarketplaceList?.data?.map((d) => ({
          label: d?.marketplace,
          value: d?.profile_id,
        }))
      );
      setMarketplaceLoading(false);
      fakeActionRules("GetUserMarketplaceList");
    } else if (GetUserMarketplaceList?.status === false) {
      setMarketplaceList([]);
      setMarketplaceLoading(false);
      fakeActionRules("GetUserMarketplaceList");
    }
  }, [GetUserMarketplaceList]);

  useEffect(() => {
    if (UpdateStatusCampaignRulesResponse?.status === true) {
      message.destroy();
      message.success(UpdateStatusCampaignRulesResponse?.message);
      setSelectedRows({
        ...selectedRows,

        campaignRules: selectedRows?.campaignRules?.map((d) => {
          if (d?.id == UpdateStatusCampaignRulesResponse?.data?.id) {
            return {
              ...d,
              status: UpdateStatusCampaignRulesResponse?.data?.status,
            };
          }
          return { ...d };
        }),
      });

      fakeActionRules("UpdateStatusCampaignRulesResponse");
    } else if (UpdateStatusCampaignRulesResponse?.status === false) {
      message.destroy();
      message.error(UpdateStatusCampaignRulesResponse?.message);
      fakeActionRules("UpdateStatusCampaignRulesResponse");
    }
  }, [UpdateStatusCampaignRulesResponse]);

  useEffect(() => {
    if (AddGroupListResponse?.status === true) {
      getList(marketplaceSelected);
      setGroupModalType("new");
      setGroupModal(false);
      message.destroy();
      message.success(AddGroupListResponse?.message || "Rule added");
      fakeActionRules("AddGroupListResponse");
    } else if (AddGroupListResponse?.status === false) {
      message.destroy();
      message.warning(AddGroupListResponse?.message || "Something Went Wrong.");
      fakeActionRules("AddGroupListResponse");
    }
  }, [AddGroupListResponse]);
  useEffect(() => {
    if (EditGroupListResponse?.status === true) {
      getList(marketplaceSelected);
      setGroupModalType("new");
      setGroupModal(false);
      message.destroy();
      message.success(EditGroupListResponse?.message || "Rule added");
      fakeActionRules("EditGroupListResponse");
    } else if (EditGroupListResponse?.status === false) {
      message.destroy();
      message.warning(
        EditGroupListResponse?.message || "Something Went Wrong."
      );
      fakeActionRules("EditGroupListResponse");
    }
  }, [EditGroupListResponse]);
  useEffect(() => {
    if (DeleteGroupListResponse?.status === true) {
      getList(marketplaceSelected);
      message.destroy();
      message.success(DeleteGroupListResponse?.message || "Group deleted");
      fakeActionRules("DeleteGroupListResponse");
    } else if (DeleteGroupListResponse?.status === false) {
      message.destroy();
      message.warning(
        DeleteGroupListResponse?.message || "Something Went Wrong."
      );
      fakeActionRules("DeleteGroupListResponse");
    }
  }, [DeleteGroupListResponse]);

  useEffect(() => {
    if (DeleteCampaignRulesResponse?.status === true) {
      getList(marketplaceSelected);
      message.destroy();
      message.success(DeleteCampaignRulesResponse?.message || "Rule deleted");
      fakeActionRules("DeleteCampaignRulesResponse");
    } else if (DeleteCampaignRulesResponse?.status === false) {
      message.destroy();
      message.warning(
        DeleteCampaignRulesResponse?.message || "Something Went Wrong."
      );
      fakeActionRules("DeleteCampaignRulesResponse");
    }
  }, [DeleteCampaignRulesResponse]);

  useEffect(() => {
    if (MoveRuleInOtherFolder?.status === true) {
      message.destroy();
      message.success(MoveRuleInOtherFolder?.message);
      setSelectedRowKeys([]);
      setSelectedGroup(null);
      getList(marketplaceSelected);
      fakeActionRules("MoveRuleInOtherFolder");
    } else if (MoveRuleInOtherFolder?.status === false) {
      message.destroy();
      message.error(MoveRuleInOtherFolder?.message);
      fakeActionRules("MoveRuleInOtherFolder");
    }
  }, [MoveRuleInOtherFolder]);

  useEffect(() => {
    if (CopyRuleToAnotherUser?.status === true) {
      message.destroy();
      message.success(CopyRuleToAnotherUser?.message);
      fakeActionRules("CopyRuleToAnotherUser");
    } else if (CopyRuleToAnotherUser?.status === false) {
      message.destroy();
      message.error(CopyRuleToAnotherUser?.message);
      fakeActionRules("CopyRuleToAnotherUser");
    }
  }, [CopyRuleToAnotherUser]);

  useEffect(() => {
    if (CopyRuleGroupToAnotherUser?.status === true) {
      message.destroy();
      message.success(CopyRuleGroupToAnotherUser?.message);
      fakeActionRules("CopyRuleGroupToAnotherUser");
    } else if (CopyRuleGroupToAnotherUser?.status === false) {
      message.destroy();
      message.error(CopyRuleGroupToAnotherUser?.message);
      fakeActionRules("CopyRuleGroupToAnotherUser");
    }
  }, [CopyRuleGroupToAnotherUser]);

  useEffect(() => {
    GetMarketplaceList();
    GetUsersList();
    return () => {};
  }, []);

  const isRules = Object.keys(selectedRows)?.length !== 0;

  useEffect(() => {
    if (GetGroupListResponse?.status === true) {
      if (isRules) {
        setSelectedRows(
          GetGroupListResponse?.data?.records?.filter(
            (d) => d?.id === selectedRows?.id
          )?.[0]
        );
      }
      setData(GetGroupListResponse?.data?.records);
      setLoading(false);
      fakeActionRules("GetGroupListResponse");
    } else if (GetGroupListResponse?.status === false) {
      message.destroy();
      message.warning(GetGroupListResponse?.message);
      setLoading(false);
      fakeActionRules("GetGroupListResponse");
    }
  }, [GetGroupListResponse]);

  const handleRuleGroupSelectUser = (userId) => {
    // if (popoverRef.current) {
    //   popoverRef.current.onClick();
    // }
    // setSelectedUser(userId);
    setSelectedValues({ ...selectedValues, user: userId });
    setMarketplaceLoading(true);
    GetMarketPlaceListByUserAction({ user_id: userId });
  };

  const showModal = (record, modalType) => {
    console.log("Selected Record:", record);
    // setSelectGroup(record?.id);
    setSelectModal(modalType);
    setSelectedValues({
      ...selectedValues,
      group: modalType === "rule_group" ? record?.id : null,
      rule: modalType === "rule" ? record?.id : null,
    });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    message.destroy();
    message.loading(
      `Copying ${selectModal === "rule_group" ? "Rule Group" : "Rule"}...`,
      0
    );

    const commonData = {
      another_user_id: selectedValues?.user,
      rule_group_id: selectedValues?.group,
      marketplace_id: selectedValues?.marketplace,
    };

    const data =
      selectModal === "rule_group"
        ? commonData
        : { ...commonData, rule_id: selectedValues?.rule };

    const action =
      selectModal === "rule_group"
        ? CopyRuleGroupToAnotherUserAction
        : CopyRuleToAnotherUserAction;

    action(data);

    setSelectedValues({
      rule: null,
      user: null,
      marketplace: null,
      group: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedValues({
        rule: null,
        user: null,
        marketplace: null,
        group: null,
      });
    }
  }, [isModalVisible]);

  // Helper function to check if a value is empty
  const isValueEmpty = (value) => {
    return value === null || value === undefined || value === "";
  };

  // Function to check if the form is valid based on the modal type
  const isFormValid = () => {
    if (selectModal === "rule_group") {
      // Check only user, marketplace, and group for rule_group
      return (
        !isValueEmpty(selectedValues.user) &&
        !isValueEmpty(selectedValues.marketplace) &&
        !isValueEmpty(selectedValues.group)
      );
    } else {
      // Check all four values for other cases
      return (
        !isValueEmpty(selectedValues.rule) &&
        !isValueEmpty(selectedValues.user) &&
        !isValueEmpty(selectedValues.marketplace) &&
        !isValueEmpty(selectedValues.group)
      );
    }
  };

  const columns = [
    {
      title: "",
      align: "left",
      fixed: "left",
      render: (d) => {
        return (
          <svg
            onClick={() => {
              setSelectedRows(d);
            }}
            width={26}
            style={{ cursor: "pointer" }}
            height={26}
            fill="#6eaf26"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.585 17.155c.443-2.42.47-4.9.083-7.33l-.064-.4a2.158 2.158 0 0 0-2.132-1.819H9.76a.06.06 0 0 1-.059-.06c0-.992-.804-1.796-1.797-1.796H5.612a2.18 2.18 0 0 0-2.164 1.92l-.273 2.269a23.73 23.73 0 0 0 .217 7.094 2.128 2.128 0 0 0 1.942 1.74l1.514.11c3.43.245 6.874.245 10.304 0l1.638-.118a1.968 1.968 0 0 0 1.795-1.61Z" />
          </svg>
        );
      },
    },
    {
      title: "#",
      align: "left",
      fixed: "left",
      render: (_, __, i) => {
        return <span>{1 + i}</span>;
      },
    },
    {
      title: "Group Name",
      align: "left",
      fixed: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.group_name || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Rules Count",
      align: "left",
      ellipsis: false,
      render: (item) => {
        return <Tag color="green">{item?.campaignRules?.length}</Tag>;
      },
    },

    {
      title: "Created At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Updated At",
      align: "center",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .add(10, "minutes")
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Copy Rule",
      align: "center",
      render: (text, record) => (
        <Button onClick={() => showModal(record, "rule_group")}>Copy To</Button>
      ),
    },

    {
      title: "Action",
      align: "center",
      width: 180,
      render: (text) => {
        const isDefaultGroup = text.group_name === "Default Rule Group";

        return (
          <div className="d-flex justify-content-around" id="userActionIcons">
            <span
              className="Edit-Icon-Antd"
              onClick={() => {
                setSelectedRow(text);
                setGroupModalType("edit");
                setGroupModal(true);
              }}
            >
              <EditTwoTone />
            </span>
            {!isDefaultGroup && (
              <Popconfirm
                title="Are you sure to delete this group?"
                placement="left"
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  message.loading("Deleting...", 0);
                  DeleteGroupListAction({ id: text?.id });
                }}
              >
                <div className="Delete-Icon-Antd d-flex">
                  <Tooltip title="Delete" placement="right">
                    <DeleteTwoTone />
                  </Tooltip>
                </div>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const handleSelectUser = (userId, rowKey, data) => {
    // if (popoverRef.current) {
    //   popoverRef.current.onClick();
    // }

    setSelectedUser(null);
    const name = userList?.find((item) => item?.id === userId)?.name;
    setPopoverVisible((prevState) => ({ ...prevState, [rowKey]: false }));

    Modal.confirm({
      title: `Are you sure you want to copy this rule to user ${name}?`,
      onOk: () => {
        message.destroy();
        message.loading("Copying...", 0);
        CopyRuleToAnotherUserAction({
          another_user_id: userId,
          rule_id: rowKey,
        });
        // message.success("Rule Copied successfully!");
      },
      onCancel: () => {
        message.info("Rule not copied.");
      },
    });
  };

  const columns_ = [
    {
      title: "#",
      align: "left",
      fixed: "left",
      render: (row) => {
        return <span>{row?.key}</span>;
      },
    },
    {
      title: "Rule Name",
      align: "left",
      fixed: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.rule_name || "-"}</ASINTooltip>;
      },
    },
    {
      title: "ASIN Included",
      align: "left",
      ellipsis: false,
      render: (item) => {
        const List = item?.asin_list?.split(",");
        if (item?.asin_list) {
          return (
            <span className="d-flex align-items-center gap-2">
              <b>{List?.[0]}</b>
              <Popover
                placement="right"
                title=""
                content={
                  <div
                    style={{
                      width: "100px",
                      wordBreak: "break-word",
                      minHeight: "20px",
                      maxHeight: "100px",
                      overflow: "auto",
                    }}
                  >
                    {item?.asin_list}
                  </div>
                }
                trigger="hover"
              >
                <InfoCircleTwoTone
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
              </Popover>
            </span>
          );
        }
        return "-";
      },
    },
    {
      title: "Rule Details",

      align: "left",
      render: (item) => {
        return (
          <span
            className="Edit-Icon-Antd"
            onClick={() => {
              setModalType("edit");
              setRuleModal(true);
              setSelectedRow(item);
            }}
          >
            <EditTwoTone />
          </span>
        );
      },
    },
    {
      title: "Campaigns",
      width: 200,
      align: "center",
      render: (item) => {
        if (item?.campaigns?.length === 0) {
          return "-";
        }
        return (
          <div
            className="campaignsView justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <ASINTooltip row={2} rule view={false}>
              {item?.campaigns}
            </ASINTooltip>
            <span
              onClick={() => {
                setCampaignsModal(true);
                setSelectedRow(item);
              }}
              className="viewMore"
            >
              <EyeOutlined />
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      render: (item) => {
        return (
          <span>
            <Tag color={item?.status == 1 ? "green" : "red"} bordered={false}>
              {item?.status == 1 ? "Active" : "In Active"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Next Execution",
      align: "center",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .add(10, "minutes")
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Last Executed",
      align: "center",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.updated_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Action Log",
      align: "center",
      render: (item) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedRow(item);
              setActionLogView(true);
            }}
          >
            <FieldTimeOutlined style={{ fontSize: "22px" }} />
          </span>
        );
      },
    },
    {
      title: "Copy Rule",
      align: "center",
      render: (text, record) => (
        <Button onClick={() => showModal(record, "rule")}>Copy To</Button>
      ),
      // render: (item, record) => {
      //   const rowKey = record.id; // Unique identifier for each row

      //   const content = (
      //     <Select
      //       style={{ width: 120 }}
      //       placeholder="Select User"
      //       value={selectedUser}
      //       onChange={(value) => handleSelectUser(value, rowKey, record)}
      //       options={userList.map((user) => ({
      //         label: user.name,
      //         value: user.id,
      //       }))}
      //     ></Select>
      //   );

      //   return (
      //     <Popover
      //       content={content}
      //       trigger="click"
      //       open={popoverVisible[rowKey]}
      //       onOpenChange={(visible) =>
      //         setPopoverVisible((prevState) => ({
      //           ...prevState,
      //           [rowKey]: visible,
      //         }))
      //       }
      //     >
      //       <Button>Copy To</Button>
      //     </Popover>
      //   );
      // },
    },
    {
      title: "Action",
      align: "center",
      width: 180,
      render: (text) => {
        return (
          <div className="d-flex justify-content-around" id="userActionIcons">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(e) => {
                message.destroy();
                message.loading("Loading...", 0);
                StatusCampaignRulesAction(text?.id, { status: e ? 1 : 0 });
              }}
              checked={text?.status == 1}
            />
            <span
              onClick={() => {
                setRuleModal(true);
                setModalType("clone");
                setSelectedRow(text);
              }}
              className="svg-icon svg-icon-primary svg-icon-1 ms-3 cursor-pointer"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                  fill="currentColor"
                />
              </svg>
            </span>

            <Popconfirm
              title="Are you sure to delete this rule?"
              placement="left"
              cancelText="No"
              // getPopupContainer={() =>
              //   document.getElementById("user-table-admin").parentNode
              // }
              okText="Yes"
              onConfirm={() => {
                message.loading("Deleting...", 0);
                DeleteCampaignRulesAction(text?.id);
              }}
            >
              <div className="Delete-Icon-Antd d-flex">
                <Tooltip
                  // getPopupContainer={() =>
                  //   document.getElementById("user-table-admin").parentNode
                  // }
                  title="Delete"
                  placement="left"
                >
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  // Row selection configuration
  const rowSelection = {
    selectedRowKeys: selectedRowKeys?.map((d) => d?.id), // Current selection
    onChange: (selectedKeys, selectedRows) => {
      // Update the state to the selected row's keys
      setSelectedRowKeys(selectedRows);
    },
  };
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  const tableData = selectedRows?.campaignRules
    ?.filter((items) => {
      return items?.rule_name
        ?.toLowerCase()
        ?.replace(/\s/g, "")
        .includes(rulesSearch.toLowerCase()?.replace(/\s/g, ""));
    })
    ?.sort((a, b) => a?.rule_name?.localeCompare(b?.rule_name));

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6 smallScreenPageHeader">Rules</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div
                className="card-header ml-auto align-items-center gap-3"
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#FFF",
                  zIndex: 999,
                  width: "100%",
                }}
              >
                <div className="card-title">
                  {isRules && (
                    <>
                      <CaretLeftOutlined
                        onClick={() => {
                          setRulesSearch("");
                          setSelectedRows({});
                        }}
                      />
                      <div>
                        <b className="ms-3">
                          {selectedRows?.group_name || "-"}
                        </b>{" "}
                        <Tag className="ms-3" bordered={false} color="blue">
                          {
                            marketplaceList?.filter(
                              (d) => d?.value === selectedRows?.profile_id
                            )?.[0]?.label
                          }
                        </Tag>
                      </div>
                    </>
                  )}
                </div>
                <div className="card-toolbar gap-3">
                  {selectedRowKeys?.length > 0 && (
                    <Dropdown
                      menu={{
                        items: [],
                      }}
                      trigger={["click"]}
                      onOpenChange={(e) => setDropdownView(e)}
                      open={dropdownView}
                      dropdownRender={(menu) => (
                        <div className="p-5" style={contentStyle}>
                          {React.cloneElement(menu, {
                            style: menuStyle,
                          })}

                          <Select
                            placeholder="Select Folder"
                            options={data?.map((d) => ({
                              label: d?.group_name,
                              value: d?.id,
                            }))}
                            onChange={(e) => {
                              setSelectedGroup(e);
                            }}
                            className="w-200px"
                            value={selectedGroup}
                          />
                          <Divider className="my-3" />
                          <Button
                            onClick={() => {
                              message.destroy();
                              setDropdownView(false);
                              message.loading("Loading...", 0);
                              MoveRuleInOtherFolderAction({
                                id: selectedRowKeys
                                  ?.map((d) => d?.id)
                                  ?.join(","),
                                groups_id: selectedGroup,
                              });
                            }}
                            className=""
                            type="primary"
                          >
                            Move
                          </Button>
                        </div>
                      )}
                    >
                      <Button
                        type="primary"
                        onClick={(e) => e.preventDefault()}
                        size="large"
                      >
                        Bulk Action
                      </Button>
                    </Dropdown>
                  )}
                  <Tooltip
                    title={`Search by ${isRules ? `Rule Name` : "Group Name"}`}
                  >
                    <Input
                      size="large"
                      className="w-200px"
                      placeholder="Search..."
                      value={isRules ? rulesSearch : filter?.search_key || null}
                      onPressEnter={() => {
                        if (isRules) {
                          return;
                        }
                        setLoading(true);
                        setData([]);
                        GetGroupListAction({
                          page: page,
                          perPage: pageSize,
                          profile_id: marketplaceSelected,
                          ...filter,
                        });
                      }}
                      onChange={(e) => {
                        if (isRules) {
                          setRulesSearch(e.target.value);
                          return;
                        }
                        setFilter({
                          ...filter,
                          search_key: e.target.value,
                        });
                      }}
                    />
                  </Tooltip>
                  {isRules && (
                    <Select
                      placeholder="Select Status"
                      size="large"
                      className="w-200px"
                      allowClear
                      value={filter?.status || null}
                      onChange={(e) => {
                        const obj = {
                          ...filter,
                          status: e,
                        };
                        setFilter(obj);
                        setLoading(true);
                        setData([]);
                        GetGroupListAction({
                          page: page,
                          perPage: pageSize,
                          profile_id: marketplaceSelected,
                          ...obj,
                        });
                      }}
                      options={[
                        { label: "Active", value: 2 },
                        { label: "In Active", value: 1 },
                      ]}
                    />
                  )}{" "}
                  {!isRules && (
                    <>
                      <Select
                        placeholder="Select Marketplace"
                        className="w-200px"
                        size="large"
                        loading={marketplaceLoading}
                        options={marketplaceList}
                        value={marketplaceSelected}
                        onChange={(e) => {
                          setLoading(true);
                          GetGroupListAction({ profile_id: e, ...filter });
                          setMarketplaceSelected(e);
                        }}
                      />

                      <div>
                        <Button
                          type="dashed"
                          size="large"
                          onClick={() => {
                            setGroupModalType("new");
                            setGroupModal(true);
                          }}
                        >
                          + New Group
                        </Button>
                      </div>
                    </>
                  )}
                  <Button
                    className="antd-success "
                    size="large"
                    onClick={() => {
                      setSelectedRow({});
                      setModalType("add");
                      setRuleModal(true);
                    }}
                  >
                    + New Rule
                  </Button>
                </div>
              </div>
              <div className="separator separator-dashed mb-0" />
              <div className="card-body py-4" id="user-table-admin">
                {loading ? (
                  <Loading />
                ) : Object.keys(selectedRows)?.length !== 0 ? (
                  <Table
                    columns={columns_}
                    rowSelection={rowSelection}
                    dataSource={tableData?.map((d, index) => ({
                      ...d,
                      key: index + 1,
                    }))}
                    rowKey="id"
                    pagination={{
                      className: "ant-table-pagination-custom-rule",
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: "max-content" }}
                  />
                ) : (
                  <ASINTable
                    columns={columns}
                    fixed="top"
                    dataSource={data}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {actionLogView && (
        <ActionLog
          show={actionLogView}
          close={() => {
            setActionLogView(false);
            setSelectedRow({});
          }}
          selectedRow={selectedRow}
          {...props}
        />
      )}

      {ruleModal && (
        <Add
          show={ruleModal}
          dataValues={selectedRow}
          getList={() => getList(marketplaceSelected)}
          GroupData={data}
          modalType={modalType}
          marketplaceSelected={marketplaceSelected}
          type={Object.keys(selectedRow)?.length === 0}
          close={() => {
            setModalType("add");
            setSelectedRow({});
            setRuleModal(false);
          }}
          {...props}
        />
      )}
      <Campaigns
        show={campaignsModal}
        close={() => setCampaignsModal(false)}
        data={selectedRow}
        {...props}
      />
      {groupModal && (
        <GroupNameModal
          open={groupModal}
          onClose={() => {
            setSelectedRow({});
            setGroupModal(false);
          }}
          type={groupModalType}
          marketplaceSelected={marketplaceSelected}
          selectedRow={selectedRow}
          {...props}
        />
      )}
      <ChangeGroup
        open={moveRuleModal}
        onClose={() => setMoveRuleModal(false)}
        data={data}
      />

      <Modal
        title={selectModal === "rule_group" ? "Copy Rule Group" : "Copy Rule"}
        visible={isModalVisible}
        footer={
          <>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <Button onClick={handleCancel}>Close</Button>
              <Button
                type="primary"
                onClick={handleOk}
                disabled={!isFormValid()}
              >
                Ok
              </Button>
            </div>
          </>
        }
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="d-flex flex-column justify-content-between gap-5 align-items-center py-5">
          <div className="d-flex justify-content-between align-items-center gap-5 w-100">
            <div className="w-50 fs-6 fw-bold text-gray-700">User:</div>
            <Select
              id="select-user"
              style={{ width: "100%" }}
              placeholder="Select User"
              value={selectedValues.user}
              options={userList.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
              onChange={(e) => handleRuleGroupSelectUser(e)}
            ></Select>
          </div>

          <div className="d-flex justify-content-between align-items-center gap-5 w-100">
            <div className="w-50 fs-6 fw-bold text-gray-700">Marketplace:</div>
            <Tooltip
              title={!selectedValues.user ? `Please Select The User` : ""}
              placement="right"
            >
              <Select
                id="select-marketplace"
                style={{ width: "100%" }}
                placeholder="Select The Marketplace"
                onChange={(e) => {
                  setSelectedValues({ ...selectedValues, marketplace: e });
                }}
                value={selectedValues.marketplace}
                loading={marketplaceLoading}
                options={userWiseMarketplaceList}
                disabled={!selectedValues.user || marketplaceLoading}
              ></Select>
            </Tooltip>
          </div>

          {selectModal === "rule" && (
            <div className="d-flex justify-content-between align-items-center gap-5 w-100">
              <div className="w-50 fs-6 fw-bold text-gray-700">Rule Group:</div>

              <Tooltip
                title={
                  !selectedValues.marketplace
                    ? `Please Select The Marketplace`
                    : ""
                }
                placement="right"
              >
                <Select
                  id="select-rule-group"
                  style={{ width: "100%" }}
                  placeholder="Select The Rule Group"
                  value={selectedValues.group}
                  onChange={(e) =>
                    setSelectedValues({ ...selectedValues, group: e })
                  }
                  loading={marketplaceLoading}
                  options={groupList}
                  disabled={!selectedValues.marketplace || marketplaceLoading}
                ></Select>
              </Tooltip>
            </div>
          )}
        </div>
      </Modal>
    </Wrapper>
  );
}
